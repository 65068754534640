import { Box, Container } from '@mui/material'

import StaticPageHeader from '../StaticPageHeader'
import StaticPageBody from '../StaticPageBody'
import { guidePageContent } from '../../../i18n/locales/en/textFields'

const Guide = () => {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 64px)',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          background: '#fff',
        }}
      >
        <Container maxWidth={'md'}>
          <StaticPageHeader
            title={guidePageContent.header.title}
            subtitle={guidePageContent.header.subtitle}
          ></StaticPageHeader>
        </Container>
      </Box>
      <Box>
        <Container maxWidth={'md'}>
          <StaticPageBody staticPageContent={guidePageContent} />
        </Container>
      </Box>
    </Box>
  )
}

export default Guide
