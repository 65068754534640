import React, { createContext, useContext, useState } from 'react'

import chatService from '../services/chat'
import { NewChatNotification } from '../models/notification'

import { AuthContext } from './loginContext'

// move it to auth context

interface NotificationsContextProps {
  acceptNotification: (answer: string) => void
  notifications: NewChatNotification[]
  // have to be any by default cause notification can be different by type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setNotifications: (body: any) => void
  notificationAccepted: boolean
  setNotificationAccepted: (body: boolean) => void
}

const defaultNotificationContext: NotificationsContextProps = {
  acceptNotification() {},
  notifications: [],
  setNotifications() {},
  notificationAccepted: false,
  setNotificationAccepted() {},
}

export const NotificationContext = createContext<NotificationsContextProps>(
  defaultNotificationContext
)

export const NotificationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const cognitoPoolId = process.env.REACT_APP_COGNITO_POOL_CLIENT_ID
  const lastAuthPeer = localStorage.getItem(
    `CognitoIdentityServiceProvider.${cognitoPoolId}.LastAuthUser`
  )
  const loginPeerToken = localStorage.getItem(
    `CognitoIdentityServiceProvider.${cognitoPoolId}.${lastAuthPeer}.idToken`
  )
  const authHeader = { Authorization: 'Bearer ' + loginPeerToken }
  const { currentSession } = useContext(AuthContext)

  const [notifications, setNotifications] = useState<NewChatNotification[]>([])
  const [notificationAccepted, setNotificationAccepted] = useState(false)

  const acceptNotification = async (answer: string) => {
    const removedNotification = notifications[notifications.length - 1]
    const updatedNotidfication = notifications.filter(
      (notification) => removedNotification.notificationId !== notification.notificationId
    )

    const session = await currentSession()
    const authHeader = { Authorization: 'Bearer ' + session?.idToken.jwtToken }

    chatService
      .postNotificationAnswer(
        authHeader,
        answer,
        removedNotification.payload,
        removedNotification.notificationId
      )
      .then(() => {
        setNotifications(updatedNotidfication)
        setNotificationAccepted(true)
      })
      .catch((err) => console.error(err))
  }

  return (
    <NotificationContext.Provider
      value={{
        acceptNotification,
        notifications,
        setNotifications,
        notificationAccepted,
        setNotificationAccepted,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}
