const { REACT_APP_CONVERSATION_BASE_URL } = process.env

export const peerLoginTokenURL =
  'https://svrcwme540.execute-api.eu-central-1.amazonaws.com/dev/peer/login'

export const websocketBaseURL = 'wss://l8k65mo3lc.execute-api.eu-central-1.amazonaws.com'

export const acceptNotificatinURL = `${REACT_APP_CONVERSATION_BASE_URL}/notification`

export const conversationsURL = `${REACT_APP_CONVERSATION_BASE_URL}/conversations`

export const baseURL = `${REACT_APP_CONVERSATION_BASE_URL}`
