import { Box, AppBar, IconButton, Typography, ListItemButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { AuthContext } from '../../context/loginContext'
import Logo from '../../assets/Logo_Final.svg'
import LogoutIcon from '../../assets/Icon_Logout.svg'
import { ReactComponent as HilfeIcon } from '../../assets/Icon_Hilfe.svg'
import routes from '../../constants/routes'

import LogoutDialog from './LogoutDialog'

const TopBar = () => {
  const { getLastAuthPeer } = useContext(AuthContext)
  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false)
  const { t } = useTranslation('base')

  return (
    <Box sx={{ '& .MuiPaper-root': { boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.08)' } }}>
      <AppBar position="static" elevation={1}>
        <Box
          sx={{
            height: '64px',
            bgcolor: 'white',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            boxShadow: 'none',
            pl: 2,
            pr: 2,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: 8,
              transform: 'scale(5)',
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            <img src={Logo} alt="DRK" height={25} width={25} />
          </Box>
          <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1, color: 'black', fontWeight: 'bold', textAlign: 'center' }}
          />
          <ListItemButton
            component={NavLink}
            to={routes.support}
            style={{
              textTransform: 'none',
              backgroundColor: '#E60005',
              fontSize: '18px',
              color: 'white',
              border: 'none',
              borderRadius: '8px',
              padding: '18px 10px 18px ',
              height: '44px',
              cursor: 'pointer',
              maxWidth: '240px',
              marginRight: '20px',
            }}
          >
            <HilfeIcon
              style={{
                stroke: '#EB8264',
                marginRight: '10px',
                marginLeft: '10px',
              }}
            />
            {t('supportButtonText')}
          </ListItemButton>
          {!getLastAuthPeer() ? (
            ''
          ) : (
            <IconButton color="primary" onClick={() => setOpenLogoutDialog(true)}>
              <img src={LogoutIcon} alt="DRK" height={25} width={25} />
              <Typography
                variant="body1"
                sx={{
                  flexGrow: 1,
                  color: 'black',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  ml: 1,
                  mr: 2,
                }}
              >
                {t('logoutButtonText')}
              </Typography>
            </IconButton>
          )}
        </Box>
      </AppBar>
      <LogoutDialog
        openLogoutDialog={openLogoutDialog}
        handleCloseLogoutDialog={() => setOpenLogoutDialog(false)}
      />
    </Box>
  )
}

export default TopBar
