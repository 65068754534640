import { Box, Container, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import StaticPageHeader from '../staticPages/StaticPageHeader'
import { useChat } from '../../context/chatContext'
import { admisStatisticsPageContent } from '../../i18n/locales/en/textFields'

const AdminStatistic = () => {
  const { statistics, getStatistics, setIsArchivedChats } = useChat()

  const [time, setTime] = useState({
    hours: 0,
    minutes: 0,
  })

  useEffect(() => {
    setIsArchivedChats(false)
    getStatistics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (statistics) {
      const hours = Math.floor(statistics.timeTotal / 3600)
      const minutes = Math.floor((statistics.timeTotal % 3600) / 60)
      setTime({ hours, minutes })
    }
  }, [statistics])

  return (
    <>
      <Box
        sx={{
          background: '#fff',
        }}
      >
        <Container maxWidth={'md'}>
          <StaticPageHeader
            title={admisStatisticsPageContent.header.title}
            subtitle={admisStatisticsPageContent.header.subtitle}
          ></StaticPageHeader>
        </Container>
      </Box>
      <Box sx={{ paddingTop: '48px' }}>
        <Container maxWidth={'md'}>
          <Typography fontSize={'32px'} fontWeight={700} lineHeight={'40px'}>
            {admisStatisticsPageContent.body[0].title}
          </Typography>
          <Box
            sx={{
              paddingTop: '32px',
              paddingBottom: '16px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'}>
              {admisStatisticsPageContent.body[0].line1}
            </Typography>
            <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'}>
              {statistics && statistics.totalConversations}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ padding: '16px 0', display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'}>
              {admisStatisticsPageContent.body[0].line2}
            </Typography>
            <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'}>
              {statistics && statistics.conversationsForMonth}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ paddingTop: '16px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'}>
              {admisStatisticsPageContent.body[0].line3}
            </Typography>
            <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'}>
              {`${time.hours} Std, ${time.minutes} Min`}
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default AdminStatistic
