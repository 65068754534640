import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useChat } from '../../context/chatContext'
import { Conversation } from '../../models/conversation'
import { conversationActiveTime } from '../../utils/getActiveConversationTime'

type Props = {
  conversation: Conversation
}

const ChatCard = ({ conversation }: Props): React.ReactElement => {
  const { t } = useTranslation('base')
  const {
    handleSelectConversation,
    selectedConversationId,
    updatedConversationIds,
    isArchivedChats,
    handleSelectArchivedConversation,
    selectedArchivedConversationId,
  } = useChat()

  const isConversationSelected = isArchivedChats
    ? conversation?.conversationId === selectedArchivedConversationId
    : conversation?.conversationId === selectedConversationId

  const handleSelectChat = (id: string) => {
    !isArchivedChats ? handleSelectConversation(id) : handleSelectArchivedConversation(id)
  }

  const isConversationUpdated =
    !isConversationSelected && updatedConversationIds?.includes(conversation.conversationId)

  const ChatSideLine = () => {
    return <div style={{ width: '5px', height: '100%', background: '#2276D0', minWidth: '5px' }} />
  }

  const ChatSeparatorLine = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '1px',
          background: '#D9D9D9',
        }}
      />
    )
  }
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '76px',
          background: isConversationSelected ? '#F2F2F2' : 'white',
          display: 'flex',
        }}
        onClick={() => handleSelectChat(conversation.conversationId)}
      >
        {isConversationSelected && <ChatSideLine />}
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px 8px',
            cursor: 'pointer',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              flexDirection: 'column',
              maxWidth: '60%',
              padding: 1,
              mt: '5px',
            }}
          >
            <Typography align="left" fontSize={'15px'} fontWeight={500} lineHeight={'22px'}>
              {t('chatCardIdChatIdTitle')} {'#' + conversation?.conversationId?.substr(-4)}
            </Typography>
            <Typography
              fontSize={'13px'}
              lineHeight={'22px'}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                width: '100%',
                fontWeight: isConversationUpdated ? 600 : 400,
              }}
            >
              {conversation?.previewMessage}
            </Typography>
          </Box>

          <Typography
            align="right"
            mt={'4px'}
            fontSize={'11px'}
            fontWeight={400}
            lineHeight={'22px'}
          >
            {conversationActiveTime(conversation)}
          </Typography>
        </Box>
      </Box>
      <ChatSeparatorLine />
    </>
  )
}

export default ChatCard
