import { useTranslation } from 'react-i18next'
import { Dialog, Box, Button, Typography } from '@mui/material'
import { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { colors } from '../../theme'
import { AuthContext } from '../../context/loginContext'
import RightArrow from '../../assets/Icon_Arrow_Right.svg'

type Props = {
  openLogoutDialog: boolean
  handleCloseLogoutDialog: () => void
}

const LogoutDialog = ({ openLogoutDialog, handleCloseLogoutDialog }: Props): React.ReactElement => {
  const { t } = useTranslation('base')
  const { logout } = useContext(AuthContext)
  const handleLogout = async () => {
    await logout()
    handleCloseLogoutDialog()
    return <Navigate replace to="/login" />
  }

  const handleClose = (event: HTMLInputElement, reason: string) => {
    if (reason && reason == 'backdropClick') return
    handleCloseLogoutDialog()
  }

  return (
    <Dialog
      open={openLogoutDialog}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: '12px' } }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '480px',
          p: '36px',
        }}
      >
        <Typography variant="h5" fontWeight="bold" textAlign={'center'}>
          {t('logoutDialogTitle')}
        </Typography>

        <Typography variant="body1" textAlign={'center'} fontWeight={500} pt={'16px'} pr={4} pl={4}>
          {t('logoutDialogTitleHelper')}
        </Typography>

        <Button
          variant="contained"
          onClick={handleLogout}
          sx={{
            background: colors.drkRot,
            width: '214px',
            height: '56px',
            borderRadius: '56px',
            mt: '32px',
            textTransform: 'none',
            boxShadow: 'none',
          }}
        >
          <Typography
            variant="body1"
            fontSize="18px"
            lineHeight="18px"
            fontWeight={700}
            color="primary.light"
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {t('logoutDialogConfirmButtonText')}{' '}
              <img src={RightArrow} width={16} height={16} style={{ marginLeft: '10px' }} />
            </Box>
          </Typography>
        </Button>

        <Button
          variant="text"
          onClick={() => handleCloseLogoutDialog()}
          sx={{ width: '214px', borderRadius: '24px', mt: 2, textTransform: 'none' }}
        >
          <Typography variant="body1" fontSize="18px" fontWeight={500} color="primary.dark">
            {t('logoutDialogBackButtonText')}
          </Typography>
        </Button>
      </Box>
    </Dialog>
  )
}

export default LogoutDialog
