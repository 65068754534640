import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'

import AccordionLine from '../chatGuide/AccordionLine'
import arrowIcon from '../../assets/Icon_arrow_gray.svg'

const AccordionIcon = () => {
  return <img src={arrowIcon} width={16} height={16} />
}

type StaticPageContent = {
  header: {
    title: string
    subtitle: string
  }
  body: {
    title?: string
    accordions: {
      title: string
      lines: string[]
    }[]
  }[]
}

const StaticPageBody = ({
  staticPageContent,
}: {
  staticPageContent: StaticPageContent
}): React.ReactElement => {
  return (
    <Box sx={{ pb: '48px' }}>
      {staticPageContent.body.map((content) => (
        <>
          {content.title ? (
            <Typography
              sx={{ paddingBottom: '32px', paddingTop: '48px' }}
              fontSize={'24px'}
              lineHeight={'28px'}
              fontWeight={700}
              key={content.title || 'key'}
            >
              {content.title}
            </Typography>
          ) : (
            <Box key={staticPageContent.header.subtitle} sx={{ paddingTop: '64px' }} />
          )}
          {content.accordions.map((accordion) => (
            <>
              <Accordion
                key={accordion.title}
                disableGutters={true}
                sx={{
                  borderRadius: '8px !important',
                  border: 'solid 1px #D9D9D9',
                  '&:before': {
                    backgroundColor: 'transparent !important',
                  },
                  marginBottom: '12px',
                  boxShadow: 'none',
                }}
              >
                <AccordionSummary expandIcon={<AccordionIcon />}>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={500}
                    lineHeight={'26px'}
                    color={'#2276D0'}
                  >
                    {accordion.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {accordion.lines.map((line, index) => (
                    <AccordionLine type="lg" text={line} key={index} />
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </>
      ))}
    </Box>
  )
}

export default StaticPageBody
