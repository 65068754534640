import i18n from 'i18next'
import { initReactI18next } from 'react-i18next/initReactI18next'

import enBase from './locales/en/base'

const langEn = {
  base: enBase,
}

const resources = {
  en: langEn,
}

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  lng: 'en',
  debug: false,
  keySeparator: '.',
  interpolation: { escapeValue: false },
  initImmediate: false,
  returnNull: false,
})

export default i18n
