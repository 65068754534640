import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'

import { ChatGuideAcordeonContentType } from '../../models/guides'
import arrowIcon from '../../assets/Icon_arrow_gray.svg'

import AccordionLine from './AccordionLine'

type Props = {
  content: ChatGuideAcordeonContentType
}

const ChatGuideAccordion = ({ content }: Props) => {
  return (
    <Box sx={{ paddingBottom: '8px' }}>
      <Accordion
        disableGutters={true}
        sx={{
          background: 'transparent',
          borderRadius: '8px !important',
          border: 'none',
          '&:before': {
            backgroundColor: 'transparent !important',
          },
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          sx={{
            width: 'max-content',
            padding: '0',
            minHeight: '0px',
            flexDirection: 'row',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
            mb: '16px',
          }}
          expandIcon={
            <img
              src={arrowIcon}
              width={'8px'}
              height={'8px'}
              style={{ marginLeft: '5px', marginRight: '5px' }}
            />
          }
        >
          <Typography fontSize={'15px'} fontWeight={500} lineHeight={'22px'} color={'#2276D0'}>
            {content.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: 0,
            mb: '12px',
            '& .MuiTypography-root': {
              p: '12px',
            },
          }}
        >
          {content.lines.map((line, index) => (
            <AccordionLine key={index} type="sm" text={line} />
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default ChatGuideAccordion
