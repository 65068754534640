import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material'
import { Amplify } from 'aws-amplify'

import App from './App'
import './i18n/i18n'
import { theme } from './theme'
import config from './aws-exports'
Amplify.configure({
  ...config,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
)
