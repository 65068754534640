import { Box } from '@mui/system'

import spinnerIcon from '../../assets/Loader_Animation.png'

const Spinner = () => {
  return (
    <Box
      sx={{
        animation: 'spin 4s linear infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
      }}
    >
      <img src={spinnerIcon} width={48} height={48} />
    </Box>
  )
}

export default Spinner
