import { useEffect, useState, useContext } from 'react'
import { Box, Container, Divider, Typography, Button, Snackbar, Alert } from '@mui/material'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

import StaticPageHeader from '../staticPages/StaticPageHeader'
import { AuthContext } from '../../context/loginContext'
import copyIcon from '../../assets/Icon_Copy.svg'
import { colors } from '../../theme'
import { useChat } from '../../context/chatContext'

const AdminConversationInfo = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('base')
  const [isNumberShown, setIsNumberShown] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isConversationIdCopied, setIsConversationIdCopied] = useState(false)

  const { conversationId } = useContext(AuthContext)
  const { conversationsFoundByConversationId, handleFoundConversationNumberByConversationId } =
    useChat()

  const toggleShowPhoneNumber = () => {
    setIsNumberShown(!isNumberShown)
  }

  useEffect(() => {
    if (conversationId) {
      handleFoundConversationNumberByConversationId(conversationId)
    } else {
      navigate('/adminStatistics')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId])

  useEffect(() => {
    if (conversationsFoundByConversationId.phoneNumber) {
      setPhoneNumber(conversationsFoundByConversationId.phoneNumber.replace(/[^0-9+]/g, ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationsFoundByConversationId])

  return (
    <>
      <Box
        sx={{
          background: '#fff',
        }}
      >
        <Container maxWidth={'md'}>
          <StaticPageHeader title={t('headerText')} subtitle=""></StaticPageHeader>
        </Container>
      </Box>
      <Box sx={{ paddingTop: '48px' }}>
        <Container maxWidth={'md'}>
          <Typography fontSize={'32px'} fontWeight={700} lineHeight={'40px'}>
            {t('titleText')}
          </Typography>
          <Box sx={{ padding: '16px 0', display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'}>
              {t('conversationIdText')} - {conversationId}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ padding: '16px 0', display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'}>
              {t('peerNameText')} - {conversationsFoundByConversationId.peer}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ padding: '16px 0', display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              fontSize={'18px'}
              fontWeight={500}
              lineHeight={'26px'}
              sx={{ display: 'flex' }}
            >
              {t('phoneNumberText')} -{' '}
              {isNumberShown ? (
                <Box
                  onClick={() => {
                    navigator.clipboard.writeText(phoneNumber)
                    setIsConversationIdCopied(true)
                  }}
                  sx={{ cursor: 'pointer', marginLeft: '10px' }}
                >
                  {phoneNumber}

                  <img src={copyIcon} alt={'copy-icon'} style={{ marginLeft: '10px' }} />
                </Box>
              ) : (
                '**********'
              )}
            </Typography>
          </Box>
          <Button
            variant="text"
            onClick={() => toggleShowPhoneNumber()}
            sx={{
              width: '214px',
              borderRadius: '24px',
              textTransform: 'none',
              background: colors.drkRot,
              border: '1px solid #E60005 !important',
            }}
          >
            <Typography
              fontSize={'14px'}
              fontWeight={500}
              lineHeight={'26px'}
              sx={{ color: 'black' }}
            >
              {isNumberShown ? 'Hide' : 'Reveal'} {t('revealBtnText')}
            </Typography>
          </Button>
        </Container>
        <Snackbar
          open={isConversationIdCopied}
          autoHideDuration={1500}
          onClose={() => setIsConversationIdCopied(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
            {t('copyToConsoleMessage')}
          </Alert>
        </Snackbar>
      </Box>
    </>
  )
}

export default AdminConversationInfo
