import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'

import { useChat } from '../../context/chatContext'

type Props = {
  title: string
  subtitle: string
}

const StaticPageHeader = ({ title, subtitle }: Props) => {
  const { setIsArchivedChats } = useChat()
  useEffect(() => {
    setIsArchivedChats(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '64px 0',
        gap: '24px',
        maxHeight: '700px',
      }}
    >
      <Typography fontSize={'48px'} fontWeight={700} lineHeight={'56px'}>
        {title}
      </Typography>
      <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'}>
        {subtitle}
      </Typography>
    </Box>
  )
}

export default StaticPageHeader
