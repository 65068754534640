/* eslint-disable prettier/prettier */
import { Box, TextField, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useChat } from '../../context/chatContext'
import SmileIcon from '../../assets/Icon_Smiley.png'
import { getTimeFormatForConversation } from '../../utils/getTimeFormatForConversation'
import { Conversation } from '../../models/conversation'
import Spinner from '../common/Spinner'

import { Message } from './Message'
import CloseChatMenu from './CloseChatMenu'
import ReportChatDialog from './ReportChatDialog'
import CloseChatDialog from './CloseChatDialog'

const Chat = (): React.ReactElement => {
  const { t } = useTranslation('base')
  const {
    setPeersMessageBody,
    peersMessageBody,
    handleSendPeersMessage,
    selectedConversationMessages,
    activeConversations,
    selectedConversationId,
    isArchivedChats,
    noActiveConversations,
    isLoadingMessages,
  } = useChat()

  const [startTime, setStartTime] = useState<string>('')

  const [openReportDialog, setOpenReportDialog] = useState<boolean>(false)
  const [openCloseChatDialog, setOpenCloseChatDialog] = useState<boolean>(false)

  const displayMessages =
    (selectedConversationId && !isArchivedChats && activeConversations.length > 0) ||
    (isArchivedChats && activeConversations.length > 0) ||
    isLoadingMessages

  useEffect(() => {
    const time = activeConversations.find(
      (conversation: Conversation) => conversation?.conversationId === selectedConversationId
    )?.createdAt
    setStartTime(getTimeFormatForConversation(time || 0))
  }, [activeConversations, selectedConversationId])

  const messagesEndRef = useRef<HTMLInputElement>(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [selectedConversationMessages])

  return (
    <Box
      sx={{
        height: '100%',
        width: isArchivedChats ? '65vw' : '100%',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#F2F2F2',
        position: isArchivedChats ? 'fixed' : '',
      }}
    >
      {selectedConversationId && !isArchivedChats && !noActiveConversations && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Typography fontSize={'11px'} fontWeight={500} lineHeight={'18px'}>
              {t('chatStartTimeLabel', { time: startTime })}
            </Typography>
          </Box>

          <CloseChatMenu
            handleOpenReportDialog={() => setOpenReportDialog(true)}
            handleOpenCloseChatDialog={() => setOpenCloseChatDialog(true)}
          />
        </Box>
      )}

      {displayMessages && (
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2, mb: isArchivedChats ? 8 : 0 }}>
          {!isLoadingMessages ? (
            <Box>
              {selectedConversationMessages?.map((message) => (
                <Message key={message?.messageId} message={message} />
              ))}
              <div ref={messagesEndRef} />
            </Box>
          ) : (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner />
            </Box>
          )}
        </Box>
      )}
      {!isArchivedChats && selectedConversationId && !noActiveConversations && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#D9D9D9',
            alignItems: 'center',
            p: 2,
          }}
        >
          {/* Smiley icon hidden - missing feature */}
          {/* <Box pr={2}>
            <img src={SmileIcon} alt="DRK" height={25} width={25} />
          </Box> */}
          <TextField
            name="message"
            size="small"
            fullWidth
            placeholder={t('messageInputPlaceholder') || ''}
            variant="outlined"
            multiline
            maxRows={3}
            value={peersMessageBody}
            onChange={(e) => setPeersMessageBody(e.target.value)}
            inputProps={{
              onKeyPress: (event) => {
                if (event.key === 'Enter') {
                  peersMessageBody && handleSendPeersMessage()
                  event.preventDefault()
                }
              },
            }}
            sx={{
              width: '517px',
              background: '#FFFFFF',
              borderRadius: '8px',
              '& .MuiFormControl-root': {
                borderRadius: '8px',
              },
              '& .MuiInputBase-root': {
                borderRadius: '8px',
              },
            }}
          />
        </Box>
      )}
      <ReportChatDialog
        openReportDialog={openReportDialog}
        handleCloseReportDialog={() => setOpenReportDialog(false)}
      />
      <CloseChatDialog
        openCloseDialog={openCloseChatDialog}
        handleCloseChatDialog={() => setOpenCloseChatDialog(false)}
      />
    </Box>
  )
}

export default Chat
