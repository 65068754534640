// login

import { title } from 'process'

export const loginFooterTexts = [' @ 2023', 'Datenschutz', 'Impressum']

// chatGuide

export const chatGuideBoxesContent = [
  { title: 'Krisenchat', link: 'https://krisenchat.de' },
  {
    title: 'Nummer gegen Kummer: Kinder- und Jugendtelefon',
    link: 'https://www.nummergegenkummer.de',
    line: '116 111',
  },
  {
    title: 'Beratung für Kinder und Jugendliche: JugendNotmail',
    link: 'https://jugendnotmail.de',
  },
  {
    title: 'Jugendberatung der Bundeskonferenz für Erziehungsberatung e.V. (bke)',
    link: 'https://jugend.bke-beratung.de',
  },
]

export const chatGuideAcordeonContent = [
  {
    title: 'Die Unterhaltung starten',
    lines: [
      'Hallo, schön, dass du dich bei unserem Peer-Chat meldest. Im RealTalk Peer-Chat unterstützen dich 16-20-jährige Peers bei deinen Problemen, anonym, sicher und kostenlos zu allem, was dich gerade belastet. Ich bin hier und höre dir gerne zu, egal zu welchem Thema. Jeder Chat dauert maximal 60 Minuten. Mein Name ist …. Wie soll ich dich nennen?',
      'Hey, ich freu mich, dass du da bist! In unserem Peer-Chat bist du nicht allein. Egal, was in dir vorgeht und ganz gleich zu welchem Thema, ich bin die nächsten 60 Minuten für dich da.  Im RealTalk Peer-Chat unterstützen dich 16-20-jährige Peers bei deinen Problemen, anonym, sicher und kostenlos. Mein Name ist …. Wie soll ich dich nennen?',
    ],
  },
  {
    title: 'Ich brauche Zeit zum Antworten',
    lines: [
      'Danke, dass du deine Sorgen/Ängste/Probleme mit mir teilst. Ich kann das gut verstehen, muss aber darüber nachdenken, was ich dir dazu schreiben/antworten kann. Ich melde mich gleich wieder bei dir.',
      'Ich muss kurz darüber nachdenken, was ich darauf antworte. Ich schreibe dir gleich zurück.',
      'Sorry, ich brauche noch einen Moment, um mir eine Antwort zu überlegen, die dir hilft. Melde mich gleich wieder.',
    ],
  },
  {
    title: 'Mehr über das Problem herrausfinden',
    lines: [
      'Was meinst du genau?',
      'Kannst du es mir noch etwas genauer erklären? Wenn ich mehr weiß, können wir gemeinsam einen besseren Lösungsweg finden.',
      'Gab es einen bestimmten Auslöser für das Problem?',
      'Gibt es jemanden, dem du bereits von deinen Sorgen erzählt hast?',
      "Wie geht's dir damit?",
      'Wie fühlst du dich damit?',
      'Welche Gedanken gehen dir durch den Kopf, wenn du darüber nachdenkst?',
      'Gibt es noch andere Punkte, über die du sprechen möchtest?',
    ],
  },
  {
    title: 'Einen Rat geben',
    lines: [
      'Hast du schon einmal darüber nachgedacht, …',
      'Wie wäre es, wenn du versuchst',
      'Es ist wichtig, dass du nicht alleine bist und dir Unterstützung holst - so wie jetzt gerade. Hast du ..',
      'Villeicht könnte es dir helfen,..',
      'Wenn du das Gefühl hast, dasss du überfordert bist, denk daran, dass ..',
    ],
  },
  {
    title: 'Verabschiedung',
    lines: [
      'Danke, dass du dich bei uns gemeldet hast. Du kannst dich immer wieder hier an den Chat wenden, wenn du jemanden zum Reden brauchst.',
      'Machs gut! Denk daran, dass du dich jederzeit an uns wenden kannst, wenn du jemanden zum Sprechen brauchst. ',
    ],
  },
]

export const guidePageContent = {
  header: {
    title: 'Textbausteine für den Chat',
    subtitle: 'Hier findest du weitere Textbausteine fürden Chat',
  },
  body: [
    {
      accordions: [
        {
          title: 'Die Unterhaltung starten',
          lines: [
            'Hallo, schön, dass du dich bei unserem Peer-Chat meldest. Im RealTalk Peer-Chat unterstützen dich 16-20-jährige Peers bei deinen Problemen, anonym, sicher und kostenlos zu allem, was dich gerade belastet. Ich bin hier und höre dir gerne zu, egal zu welchem Thema. Jeder Chat dauert maximal 60 Minuten. Mein Name ist …. Wie soll ich dich nennen?',
            'Hey, ich freu mich, dass du da bist! In unserem Peer-Chat bist du nicht allein. Egal, was in dir vorgeht und ganz gleich zu welchem Thema, ich bin die nächsten 60 Minuten für dich da.  Im RealTalk Peer-Chat unterstützen dich 16-20-jährige Peers bei deinen Problemen, anonym, sicher und kostenlos. Mein Name ist …. Wie soll ich dich nennen?',
          ],
        },
        {
          title: 'Mehr über das  Problem herrausfinden',
          lines: [
            'Was meinst du genau?',
            'Kannst du es mir noch etwas genauer erklären? Wenn ich mehr weiß, können wir gemeinsam einen besseren Lösungsweg finden.',
            'Gab es einen bestimmten Auslöser für das Problem?',
            'Gibt es jemanden, dem du bereits von deinen Sorgen erzählt hast?',
            "Wie geht's dir damit?",
            'Wie fühlst du dich damit?',
            'Welche Gedanken gehen dir durch den Kopf, wenn du darüber nachdenkst?',
            'Gibt es noch andere Punkte, über die du sprechen möchtest?',
          ],
        },
        {
          title: 'Einen Rat geben',
          lines: [
            'Hast du schon einmal darüber nachgedacht, …',
            'Wie wäre es, wenn du versuchst',
            'Es ist wichtig, dass du nicht alleine bist und dir Unterstützung holst - so wie jetzt gerade. Hast du ..',
            'Villeicht könnte es dir helfen,..',
            'Wenn du das Gefühl hast, dasss du überfordert bist, denk daran, dass ..',
          ],
        },
        {
          title: 'Ich brauche Zeit zum Antworten',
          lines: [
            'Danke, dass du deine Sorgen/Ängste/Probleme mit mir teilst. Ich kann das gut verstehen, muss aber darüber nachdenken, was ich dir dazu schreiben/antworten kann. Ich melde mich gleich wieder bei dir.',
            'Ich muss kurz darüber nachdenken, was ich darauf antworte. Ich schreibe dir gleich zurück.',
            'Sorry, ich brauche noch einen Moment, um mir eine Antwort zu überlegen, die dir hilft. Melde mich gleich wieder.',
          ],
        },
        {
          title: 'Bei Beleidigungen',
          lines: [
            'Ich finde es nicht in Ordnung, wie du mit mir redest. Ich versuche, dir zu helfen, aber so kann ich das leider nicht. Bitte achte darauf, was und wie du mir schreibst.',
            'Ich möchte nicht von dir beleidigt/so behandelt werden. Lass das oder ich beende den Chat sofort.',
            'Ich finde es schade, wie du dich ausdrückst und mir gegenüber verhältst. Ich möchte dich gerne bei deinen Problemen unterstützen, aber wenn du deinen Umgangston nicht änderst, muss ich diese Konversation beenden und den Chat melden. Wenn der Chat gemeldet wurde, bedeutet das, dass du uns erst einmal nicht kontaktieren kannst. Der Chat wird dann durch unsere Admins geprüft, und deine Nummer könnte für Real Talk blockiert werden. Das bedeutet, dass du den Peer-Chat dann nicht mehr nutzten kannst. ',
          ],
        },
        {
          title: 'Das Thema übersteigt meine Fähigkeiten',
          lines: [
            'Leider kann ich dir bei diesem Thema nicht weiterhelfen, da es meine Fähigkeiten als Peer-Beraterin übersteigt. Möchtest du gerne professionelle Hilfe?  Ich kann dir Links zu Beratungsstellen schicken, wenn du das möchtest.',
            'Danke, dass du das mit mir teilst. Ich denke nicht, dass ich dir bei diesen Fragen helfen kann, da sie über meine Fähigkeiten als Peer-Beraterin hinausgehen. Möchtest du gerne professionelle Hilfe? Ich kann dir Links zu Beratungsstellen schicken, die dir dabei besser helfen können.',
            'Gerne, hier findest du Hilfe:',
            'Leider können wir dir mit diesem Problem in der Peer-Beratung nicht weiterhelfen. Ich habe verstanden, dass du derzeit keine professionelle Beratung in Anspruch nehmen möchtest. Deine Probleme übersteigen jedoch meine Fähigkeiten als Peer-Beraterin. Professionelle Beraterinnen sind gut geschult, um empathisch und verständnisvoll auf deine Probleme zu reagieren und Sie mit dir zu lösen. Wenn du dich zu einem späteren Zeitpunkt für eine professionelle Beratung entscheidest, kannst du uns gerne hier im Peer-Chat schreiben und nach weiteren Kontakten dazu fragen.',
          ],
        },
        {
          title: 'Verabschiedung',
          lines: [
            'Danke, dass du dich bei uns gemeldet hast. Du kannst dich immer wieder hier an den Chat wenden, wenn du jemanden zum Reden brauchst. ',
            'Machs gut! Denk daran, dass du dich jederzeit an uns wenden kannst, wenn du jemanden zum Sprechen brauchst. ',
          ],
        },
        {
          title: 'Notfall',
          lines: [
            `Dein Problem ist sehr akut. Ich denke, du brauchst jetzt sofort professionelle Hilfe, die wir hier im Peer-Chat nicht leisten können. Bitte wende dich direkt an die entsprechenden Notfallstellen. Wenn du in Not oder Gefahr bist, wähle den kostenfreien Notruf:

            Polizei: 110
            Feuerwehr und Notfall-Rettungsdienst: 112`,
            'Vielen Dank, dass du dich an mich gewandt hast und mir von deiner Situation erzählst. Es tut mir leid zu hören, dass du dich in einer so schwierigen Lage befindest. Deine Sicherheit und dein Wohlbefinden sind mir sehr wichtig, und ich möchte sicherstellen, dass du die bestmögliche Unterstützung erhältst. Es ist wichtig zu betonen, dass meine persönliche Kompetenz begrenzt ist und ich nicht die erforderliche Fachausbildung habe, um in solchen kritischen Situationen umfassende Hilfe zu leisten. In diesem Fall habe ich bereits mit unserer Peer-Begleitung gesprochen, um dir weiterhelfen zu können. Sie ist während meiner Schicht dafür da, um Unterstützung zu bieten und die bestmögliche Lösung in einer Krisensituation zu gewährleisten.',
            'Wenn jemand uns deutlich ankündigt, dass er sich selbst oder anderen Schaden zufügen will und er sich nicht auf unsere Hilfsangebote einlassen kanns, müssen wir zu seinem Schutz und auch zu unserem rechtlichen Schutz die zuständigen Behörden (Krisendienst, Polizei) darüber informieren. Denn wir wollen natürlich, dass du und alle anderen sicher sind! Bisher hast du uns Dinge erzählt, aus denen wir schließen, dass du dich selbst oder andere in Gefahr bringen könntest. Um zu verhindern, dass es dazu kommt, werden wir diese Situation und deine Informationen den zuständigen Behörden mitteilen. Bitte verstehe, dass wir dies tun, weil uns dein Wohl und das Wohl anderer sehr am Herzen liegt. Wir sind hier, um zu helfen, und wir möchten sicherstellen, dass du die Unterstützung erhältst, die du brauchst.',
          ],
        },
      ],
    },
  ],
}

export const furtherAdvisoryContent = {
  header: {
    title: 'Weiterführende Beratungsangebote',
    subtitle:
      'Hier findest du weiterführende Beratungsangebote, die du den Jugendlichen schicken kannst.',
  },
  body: {
    title: ' Deutschlandweite Angebote',
    accordions: [
      {
        title: 'Allgemeine Beratungsangebote',
        content: [
          `
<p>Krisenchat</p>
<a href='https://krisenchat.de/en'>krisenchat.de</a>

<p>Krisenchat bietet kostenfreie und vertrauliche Beratung für Kinder, Jugendliche und junge Erwachsene unter 25 Jahren über Chat an. Das Angebot ist jeden Tag rund um die Uhr über WhatsApp und SMS, ohne Anmeldung und Registrierung erreichbar. Bei allen Ängsten, Problemen und Sorgen können Betroffene hier Hilfe finden. Der Krisenchat berät auch auf Ukrainisch und Russisch.</p>`,
          ,
          `
<p>Nummer gegen Kummer: Kinder- und Jugendtelefon </p>
<a href='https://www.nummergegenkummer.de/'>nummergegenkummer.de</a>

<p>Das Kinder- und Jugendtelefon der “Nummer gegen Kummer” ist anonym und kostenlos vom Handy und Festnetz erreichbar: 116 111</p>

<p>montags bis samstags von 14 bis 20 Uhr sowie</p>
<ul>
<li>über Mail oder</li>
<li>über Chat.</li>
</ul>

<p>Die Beraterinnen und Berater helfen bei allen Problemen, wie zu Beispiel Stress mit Eltern, Freunden oder Mitschülern, Mobbing oder Abzocke im Internet oder in der Schule, Angst, Missbrauch, Essstörungen, Depression oder Sucht.</p>

<p>Ein Anruf bei der „Nummer gegen Kummer“ ist anonym und wird vertraulich behandelt. Das bedeutet auch, dass das Gespräch nicht auf der Telefonrechnung erscheint.</p>`,
          `
<p>Beratung für Kinder und Jugendliche: JugendNotmail </p>
<a href='https://jugendnotmail.de/'>jugendnotmail.de</a>

<p>Kinder und Jugendliche in einer Krise können bei JugendNotmail eine vertrauliche, kostenlose und professionelle Online-Beratung bekommen. Die Beraterinnen und Berater aus den Bereichen Psychologie und Sozialpädagogik bieten Hilfe bei Themen wie Depression, Selbstverletzung, Suizidgedanken, Gewalt, Mobbing, Missbrauch, familiäre Probleme oder Essstörungen.</p>

<p>Die Beratung ist möglich:</p>
<ul>
<li>per E-Mail</li>
<li>im Gruppenchat oder</li>
<li>in offenen Foren.</li>
</ul>

<p>Das Online-Beratungsangebot der JugendNotmail steht außerdem als App „Junoma“ zum kostenfreien Download in den gängigen App-Stores bereit.</p>`,
          `
<p>Jugendberatung der Bundeskonferenz für Erziehungsberatung e.V. (bke)</p>
<a href='https://jugend.bke-beratung.de/views/home/index.html'>jugend.bke-beratung.de</a>

<p>Die bke-Jugendberatung hilft bei kleinen und großen Sorgen, zum Beispiel bei Streit und Ärger mit den Eltern, mit Freunden, in der Schule oder bei Liebeskummer. Bei der bke-Jugendberatung können sich Jugendliche mit erfahrenen Beraterinnen und Beratern oder anderen Jugendlichen austauschen. Die bke-Jugendberatung ist anonym, kostenfrei und datensicher.</p>

<p>Der Austausch ist möglich:</p>
<ul>
<li>per Mail</li>
<li>per Einzelchat</li>
<li>in Gruppenchats</li>
<li>in verschiedenen Themen-Foren</li>
</ul>

<p>Das Online-Beratungsangebot der JugendNotmail steht außerdem als App „Junoma“ zum kostenfreien Download in den gängigen App-Stores bereit.</p>`,
        ] as string[],
      },
      {
        title: 'Sexueller Missbrauch',
        content: [
          `
<p>Hilfe-Telefon Sexueller Missbrauch für Kinder und Jugendliche</p>
<a href='https://www.hilfe-telefon-missbrauch.online/'>hilfe-telefon-missbrauch.online</a>

<p>Beim Hilfe-Telefon Sexueller Missbrauch finden Kinder und Jugendliche Hilfe: kostenfrei, vertraulich und anonym. Das Team aus psychologisch und pädagogisch ausgebildeten Fachkräften berät zu Themen wie Anmache, Mobbing in der Schule, sexueller Missbrauch, Cybermobbing, ungewollte Zusendung von Pornos, Sexting oder andere sexuelle Übergriffe.</p>

<p>Die Beratung ist möglich:</p>

<p>per Telefon: 0800 22 55 530 montags, mittwochs und freitags von 9 bis 14 Uhr sowie dienstags und donnerstags von 15 bis 20 Uhr oder</p>

<p>per Online-Beratung über Mail oder Chat.</p>`,
        ] as string[],
      },
      {
        title: 'Suizidgedanken',
        content: [
          `
<p>U 25- Mailberatung für junge Menschen in Suizidgefahr</p>
<a href='https://www.u25-deutschland.de/'>u25-deutschland.de</a>

<p>Eine vertrauliche und kostenlose Beratung für Jugendliche und junge Erwachsene unter 25 Jahren in Lebenskrisen und Gedanken an Suizid bietet U25. Die Beratung erfolgt ausschließlich anonym per Mail und ist erreichbar unter www.u25-deutschland.de.</p>

<p>Dich beraten bei [U25] Peers online, kostenlos und vertraulich.
  Du kannst so lange mit deinem*r Peer Kontakt haben, wie du magst. Du meldest dich einfach mit einem Nickname über den Helpmail-Button an. Die erste Nachricht bekommst du innerhalb von 48 Stunden, danach antwortet dir dein*e Peer innerhalb von 7 Tagen.[DJ1]</p>

  <p>Der Austausch ist möglich:</p>
<ul>
<li>per Mail</li>
</ul>`,
        ] as string[],
      },
      {
        title: 'Weitere Beratungsangebote',
        content: [
          `
<p>Pausentaste: für Kinder und Jugendliche, die sich um ihre Familie kümmern</p>
<a href='https://www.pausentaste.de/hilfe/'>pausentaste.de/hilfe</a>

<p>Das Projekt “Pausentaste” unterstützt junge Pflegende mit gezielter Beratung und Information.</p>

<p>Unter der Nummer 116 111 erreichen ratsuchende Kinder und Jugendliche die Hotline von Montag bis Samstag jeweils von 14 bis 20 Uhr. Das Beratungsangebot ist kostenlos und auf Wunsch auch anonym. Auch Chat-Beratung ist möglich.</p>`,
          `
<p>Hilfe für junge Menschen auf der Straße:</p>

<a href='https://sofahopper.de/'>sofahopper.de</a>

<p>Auf “sofahopper.de: für obdachlose junge Menschen ” finden junge Menschen Hilfe, die wohnungslos sind oder Gefahr laufen, ihre Bleibe zu verlieren. Per Chat oder per Mail gibt es Beratung bei familiären oder schulischen Sorgen, beim Umgang mit Behörden, Schulden oder gesundheitlichen Problemen. Die Beraterinnen und Berater können auch juristische Hilfe geben, wenn kurzfristig ein Personalausweis benötigt wird oder eine Postadresse für Briefe vom Amt. Das Angebot ist für Minderjährige und junge Volljährige bis zum 27. Geburtstag. </p>`,
          `
<p>Beratung von jungen Menschen mit Migrationshintergrund</p>
<a href='https://beratung.jugendmigrationsdienste.de/website'>beratung.jugendmigrationsdienste.de/website</a>

<p>Auf jmd4you finden jungen Menschen mit Migrationshintergrund Hilfe und Beratung in verschiedenen Sprachen. Bei dem Angebot der Jugendmigrationsdienste stehen JMD-Beraterinnen und JMD-Berater für Online-Fragen zur Verfügung. Das Angebot ist kostenfrei und anonym. Zudem gibt es ein Forum zum Austausch.</p>`,
          `
<p>Hilfe bei Stress im Netz: jugend.Support</p><a href='https://www.juuuport.de/jugendsupport'>juuuport.de/jugendsupport</a>
<p>und</p>
<a href='https://www.juuuport.de/'>juuuport.de</a>

<p>Bei jugend.Support finden Kinder und Jugendliche Hilfe bei Problemen im Internet, in den sozialen Medien oder mit Apps. Die Website vermittelt Hilfe zu verschiedenen Themen wie Abzocke, Belästigung, Cybermobbing, Fake News, Extremismus, Hass im Netz und Online-Sucht. Zudem finden Kinder und Jugendliche Tipps zur Selbsthilfe und schnelle Hilfe im Notfall. Die Plattform dient als Verteilerstelle und leitet junge Menschen an Beratungsstellen weiter, die vertraulich und kostenlos weiterhelfen.</p>

<p>Hilfe bei Cybermobbing & anderen Online-Problemen  ist eine bundesweite Online-Beratungsplattform für junge Menschen, die Probleme im Netz haben. Ehrenamtlich aktive Jugendliche und junge Erwachsene helfen bei Online-Problemen wie Cybermobbing, Stress in sozialen Medien, Online-Abzocke und Datenklau. Die Beratung per Kontaktformular oder WhatsApp von Montag bis Freitag von 18 bis 20 Uhr ist vertraulich und kostenlos</p>`,
        ] as string[],
      },
    ],
  },
}

export const furtherAdvisoryPageContent = {
  header: {
    title: 'Weiterführende Beratungsangebote',
    subtitle:
      'Hier findest du weiterführende Beratungsangebote, die du den Jugendlichen schicken kannst.',
  },
  body: [
    {
      title: 'Lokale Angebote',
      accordions: [
        {
          title: 'Schulsozialarbeiter:innen',
          lines: ['Hallo! Willkommen in der Chat', 'Worüber möchtest du sprechen?'],
        },
        {
          title: 'Allgemeine Beratungsangebote',
          lines: ['Hallo! Willkommen in der Chat', 'Worüber möchtest du sprechen?'],
        },
      ],
    },
    {
      title: 'Deutschlandweite Angebote',
      accordions: [
        {
          title: 'Allgemeine Beratungsangebote',
          lines: ['Hallo! Willkommen in der Chat', 'Worüber möchtest du sprechen?'],
        },
        {
          title: 'Das Problem herausfinded',
          lines: ['Hallo! Willkommen in der Chat', 'Worüber möchtest du sprechen?'],
        },
      ],
    },
  ],
}

export const helpPageContent = {
  header: {
    title: 'FAQ',
    subtitle:
      'Hier findest du alle Informationen rund um dein Ehrenamt bei RealTalk. Sollte dir eine Information fehlen, kontaktiere uns bitte direkt, damit wir sie hier ergänzen können. ',
  },
  body: {
    accordions: [
      {
        header: 'Alles zum Thema Schichten',
        title: 'Wie läuft eine Schicht ab?',
        content: [
          `<h5>Jede Schicht dauert 2, 5 Stunden </h5>
           <h5>Vor der Schicht:</h5>
          <ul>
          <li>Trage dich zu Beginn des Monats für eine Schicht im nächsten Monat ein, du wirst im Signal Chat dazu erinnert. z.B. planen wir Anfang Juli die Schichten für August.</li>
          <br/>
          <li>Es gibt für jeden Peer einen eigenen Kanal in Signal, in diesem Kanal sind immer die Schichtbegleitungen und du.</li>
          <br/>
          <li>Eine Woche vor der Schicht und am Tag der Schicht senden wir dir eine Erinnerung über Signal.</li>
          <br/>
          <li>Am Tag vor der Schicht erhältst du den Link für den digitalen Videocall zum Check-in über Signal.</li>
          <br/>
          <li>Im 15-minütige Check-in gehst du den Ablauf der Schicht, mit der Schichtbegleitung durch. Bei Fragen ist die Schichtbegleitung für dich da. </li>
          <br/>
          <li>Der Check In und Check-out sind verpflichtend; bitte halte deine Kamera während des Calls an.</li>
          `,
          `
          <h5>Während der Schicht:</h5>
          <ul>
          <li>Nachdem dem 15-minütigen Check-in </li>
          <br/>
          <li>Während der Schicht kannst du die Schichtbegleitung über Signal erreichen.</li>
          <br/>
          <li>Bei Bedarf kannst du Unterstützung entweder im Chat bei Signal oder über einen Videocall mit deiner Schichtbegleitung erhalten.</li>
        `,
          `
          <h5>Nach der Schicht:</h5>
          <ul>
          <li>Am Ende der Schicht folgt ein Check-out mit der Schichtbegleitung, dieses dauert ebenfalls 15 Minuten</li>
          <br/>
          <li>Der Check-out ist ebenfalls verpflichtend</li>
          <br/>
          <li>Die Schichtbegleitung stellt dir einige Fragen zum Ablauf deiner Schicht.</li>
          <br/>
          <li>Solltest du dich nach der Schicht unwohl fühlen oder noch Fragen haben, steht die Schichtbegleitung bereit, um diese mit dir zu klären und Probleme zu lösen.</li>
        `,
        ] as string[],
      },
      {
        title: 'Wie finde ich heraus, wann meine Schicht ist?',
        content: [
          `<p>In der Gruppenbeschreibung unserer Signalgruppe „RealTalk“ findest du immer den aktuellen Link zum Schichtplan für diesen Monat sowie einen Link zum Schichtplan für den nächsten Monat. Zudem teilen wir den Schichtplan auch in der Signalgruppe.</p>
          `,
        ] as string[],
      },
      {
        title: 'Wie oft muss ich eine Schicht übernehmen?',
        content: [
          `<p>Wir wünschen uns, dass du mindestens eine Schicht pro Monat übernimmst. Manchmal können stressige Zeiten in der Schule oder Uni oder Probleme zu Hause auftreten. Wenn du in solchen Phasen Schwierigkeiten hast, sprich bitte so früh wie möglich mit uns darüber, damit wir Bescheid wissen und dich nicht Aanfragen. Das absolute Maximum beträgt drei Schichten pro Monat.</p>
          `,
        ] as string[],
      },
      {
        title: 'Wo kann ich mich für eine Schicht eintragen?',
        content: [
          `<p>Trage dich bitte zu Beginn des Monats für eine Schicht im nächsten Monat ein. Wir planen immer einen Monat im Voraus. Zum Beispiel planen wir Anfang Juli die Schichten für August und schicken dir eine Umfrage über den Signal-Chat. Du erhältst eine Erinnerung im Signal-Chat, wenn es Zeit ist, dich einzutragen.</p>
          `,
        ] as string[],
      },
      {
        title: 'Wo kann ich mich melden, wenn ich eine Schicht nicht wahrnehmen kann?',
        content: [
          `<p>Wenn du eine Schicht nicht wahrnehmen kannst, weil du krank bist oder dir etwas dazwischenkommt, melde dich bitte so früh wie möglich über Signal bei uns. Es ist wichtig, dass du die Schichten ernst nimmst, nachdem du dich angemeldet hast. Wenn du eine Schicht wechseln/tauschen möchtest, kannst du gerne andere Peers in der RealTalk-Chatgruppe fragen, ob jemand deine Schicht übernehmen kann</p>
          `,
        ] as string[],
      },
      {
        header: 'Unterstützung vor, während oder nach dem Chat',
        title: 'Ich habe ein Problem oder mich belastet etwas. Wen kann ich dafür ansprechen?',
        content: [
          `
        <p>Wenn du ein Problem hast oder etwas dich belastet, kannst du auf verschiedene Arten Unterstützung bekommen:
           Wir sind für dich da und möchten dich Unterstützen!</p>
        <p>1. <strong>Über Signal:</strong> Du kannst uns, das Begleitteam, immer über unseren gemeinsamen Chat ansprechen. Wir sind wochentags von 09:00 bis 17:00 Uhr erreichbar und antworten dir dann so schnell wie möglich.</>
        <p>2. <strong>Sich direkt an eine Person aus dem Begleitteam wenden:</strong> Wenn du lieber direkt mit jemandem aus dem Begleitteam sprechen möchtest, kannst du uns Özüm, Laura, Delia oder Christiane auch direkt über Signal anschreiben.</>
        <p>3. <strong>Check-in und Check-out:</strong> Jeden Tag kannst du uns 15 Minuten vor oder nach deiner Schicht beim Check-in oder Check-out ansprechen.</>
        <p>4. <strong>Fallbesprechungen:</strong> Einmal pro Woche bieten wir dir eine Fallbesprechung an. Hier kannst du in der Gruppe über deine Erfahrungen sprechen und dich mit uns und anderen austauschen. Die Termine hierfür geben wir rechtzeitig über Signal bekannt. Die Teilnahme ist freiwillig.</>
        <p>5. <strong>Supervision:</strong> Zusätzlich gibt es einmal im Monat eine digitale Gruppensupervision, bei der du über alles sprechen kannst, was dich beschäftigt. Diese Supervision wird von jemandem außerhalb des DRK durchgeführt und ist vertraulich. Die Termine hierfür geben wir rechtzeitig über Signal bekannt. </>
        <p>6. <strong>Einzelsupervision bei Bedarf:</strong> Wenn dich ein Fall besonders belastet und du noch mehr Gesprächsbedarf hast, kannst du auch eine Einzelsupervision in Anspruch nehmen. Hier hast du die Möglichkeit, in einem vertraulichen Gespräch mit Supervisorinnen, die nicht Teil des Begleitteams sind, deine Sorgen und Probleme ausführlich zu besprechen. Diese Informationen werden nicht an das Begleitteam weitergegeben. Wenn du Bedarf an einer Einzelsupervision hast, melde dich bitte bei uns. Wir werden dann gemeinsam mit dir und den Supervisoren einen passenden Termin organisieren.</>
      
      `,
        ] as string[],
      },
      {
        title: 'Wer sind meine Ansprechpersonen bei RealTalk?',
        content: [
          `
        <p>Begleitteam:</p>
        <p>Das Begleitteam besteht aus mehreren Kolleginnen und Kollegen, die direkt im Projekt arbeiten. Wir unterstützen dich und begleiten dich während deines Ehrenamts. Wir planen eure Aus- und Weiterbildungen sowie die Supervisionen und sind für dich da, wenn du uns brauchst.</>
        <p>Du erreichst uns über unsere Signal-Gruppe „Realtalk“. Außerdem hat das Begleitteam mit jedem von euch eine eigene Signal-Gruppe, um euch bei euren Schichten zu erreichen, den Check-in-Link zu senden und während der Schicht für euch da zu sein.</>
        <p>Du kannst uns, das Begleitteam, immer über unseren gemeinsamen Chat ansprechen. Wir sind wochentags von 09:00 bis 17:00 Uhr und während der Schichten  über Signal oder über Mail (realtalk@drk.de) für euch erreichbar und antworten dir dann so schnell wie möglich.</>
        </br>
        <p>Supervisionsteam:</>
        <p>Das Supervisionsteam führt einmal im Monat die Supervisionen mit euch durch. Diese Supervisoren arbeiten nicht beim DRK. Die Gespräche mit ihnen sind vertraulich, das bedeutet, sie geben keine Informationen an uns weiter, wenn ihr das nicht möchtet.</>
        <p>Falls ihr Bedarf habt, könnt ihr auch Einzelsupervisionen mit dem Supervisionsteam vereinbaren. Die Termine werden über das Begleitteam organisiert</>
      
      `,
        ] as string[],
      },
      {
        title: 'Was ist eine Fallbesprechung?',
        content: [
          `
        <p>Einmal pro Woche bieten wir dir die Möglichkeit zu einer Fallbesprechung an. In diesen Gruppengesprächen kannst du deine Erfahrungen teilen und dich mit uns und anderen austauschen. Die Termine werden rechtzeitig über Signal bekannt gegeben.</p>
        <p>Die Fallbesprechungen werden vom Begleitteam moderiert. Zu Beginn des Gesprächs sammeln wir eure Herausforderungen, die dann in der Gruppe besprochen werden. Gemeinsam überlegen wir, wie wir dich am besten unterstützen können und welche Erkenntnisse wir für die Gruppe aus dem Gespräch mitnehmen können.</p>
      `,
        ] as string[],
      },
      {
        title: 'Was ist eine Supervision?',
        content: [
          `
        <p>Die Supervision findet einmal im Monat für 2 Stunden digital statt und wird von externen Supervisoren durchgeführt, die nicht im Begleitteam sind. Eine Supervision ist ein professionelles Beratungsgespräch. In diesen Terminen kannst du über deine Aufgaben im Ehrenamt und persönliche Herausforderungen sprechen. Alles, was du dort besprichst, bleibt vertraulich und wird nicht an das Begleitteam weitergegeben. Falls es Lösungen oder wichtige Erkenntnisse gibt, die für die alle Peers wichtig sind, werden diese nur anonym weitergegeben.
          <strong>Du musst einmal alle drei Monate, einmal im Quartal, an einer Supervision teilnehmen.</strong> Das bedeutet, z.B. von Januar bis März musst du mindestens an einer der Sitzungen teilnehmen. Eure Teilnahme an den Supervisionen wird von den Supervisoren notiert und an uns weitergegeben. So können wir sicherstellen, dass ihr regelmäßig an den Supervisionen teilnehmt und euch die nötige Unterstützung erhaltet.</p>
      
      `,
        ] as string[],
      },
      {
        title: 'Was ist der Unterschied zwischen Fallbesprechung und Supervision?',
        content: [
          `
        <p><strong>Fallbesprechung:</strong> Findet einmal pro Woche statt und wird vom Begleitteam moderiert. Ziel ist es, konkrete Herausforderungen in den Beratungen zu besprechen, um euch nach einer schwierigen Beratung zu entlasten und sich in der Gruppe auszutauschen und gemeinsame Lösungen zu finden. Die Teilnahme an der Fallbesprechung ist freiwillig.</p>
        </br>
        <p><strong>Supervision:</strong> Findet einmal im Monat statt und wird von eine externen Supervisoren durchgeführt. Diese Sitzung hilft dir, dein Ehrenamt und persönlichen Herausforderungen zu reflektieren. Die Supervision ist vertraulich, das bedeutet, die Informationen werden nicht an das Begleitteam weitergegeben. Sollten sich jedoch Lösungen oder Erkenntnisse ergeben, die für alle wichtig sind, werden diese anonym mitgeteilt. <strong>Die Supervison ist einmal in drei Monaten verpflichtend für euch.</strong></p>
        </br>
        <p>Termine für beide Veranstaltungen werden rechtzeitig über Signal bekannt gegeben.</>
      `,
        ] as string[],
      },
      {
        title: 'Wie oft muss ich an einer Supervision teilnehmen?',
        content: [
          `
        <p>Du kannst monatlich an der Supervision teilnehmen. Wir teilen die Termine und die Links dazu rechtzeitig über den Signal-Chat mit. Du musst einmal alle drei Monate, also einmal im Quartal, an einer Supervision teilnehmen. Das bedeutet, dass du z.B. von Januar bis März mindestens an einer der Sitzungen teilnehmen musst. Eure Teilnahme an den Supervisionen wird von den Supervisoren notiert und an uns weitergegeben. So können wir sicherstellen, dass ihr regelmäßig an den Supervisionen teilnehmt und die nötige Unterstützung erhaltet.</p>
      `,
        ] as string[],
      },
      {
        title: 'Wie kann ich an einer Supervision teilnehmen?',
        content: [
          `
        <p>Du musst dich für die Supervision nicht noch einmal gezielt anmelden, sondern einfach zu den Terminen erscheinen. Die Supervision findet zu einem festen Termin, einmal im Monat, statt. Wir teilen die Termine und die Links dazu rechtzeitig über den Signal-Chat mit. Du musst einmal alle drei Monate, also einmal im Quartal, an einer Supervision teilnehmen. Eure Teilnahme an den Supervisionen wird von den Supervisoren notiert und an uns weitergegeben. So können wir sicherstellen, dass ihr regelmäßig an den Supervisionen teilnehmt und die nötige Unterstützung erhaltet.</p>
      `,
        ] as string[],
      },
      {
        header: 'Unterstützung bei technischen Problemen',
        title: 'Was mache ich bei technischen Problemen?',
        content: [
          `
        <p>Wende dich bei technischen Herausforderungen gerne direkt an unser Peer-Begleitteam über Signal oder per E-Mail (realTalk@drk.de).</p>
      `,
        ] as string[],
      },
      {
        header: 'Weiterbildungen',
        title: 'Wo finde ich alle Informationen zu digitalen Weiterbildungsmöglichkeiten?',
        content: [
          `
        <p>Einmal im Monat findet eine digitale Weiterbildung für euch statt, die zwischen 1 und 1,5 Stunden dauert. Die Termine für die Weiterbildungen sowie Erinnerungen dazu geben wir euch rechtzeitig über den Signal-Chat bekannt. Für die Weiterbildungen müsst ihr euch nicht noch einmal gezielt anmelden. Die Teilnahme an den digitalen Weiterbildungen ist freiwillig. Wenn ihr Themenvorschläge habt, freuen wir uns sehr darüber. Andernfalls fragen wir diese auch über die Signalgruppe oder bei Netzwerktreffen bei euch ab.</p>
      `,
        ] as string[],
      },
      {
        header: 'Netzwerktreffen',
        title: 'Was ist ein Netzwerktreffen?',
        content: [
          `
        <p>Zweimal im Jahr finden Netzwerktreffen irgendwo in Deutschland statt. Bei diesen Treffen könnt ihr ein Wochenende lang zusammenkommen. Das Netzwerktreffen dient dazu, dass ihr euch als Peers besser kennenlernt und euch über eure Erfahrungen austauschen könnt. Zudem erhaltet ihr Workshops und Wissen zu Themen, die euch interessieren. Diese besprechen wir im Voraus mit euch. Die Termine geben wir euch rechtzeitig über die Signalgruppe bekannt. Die bundesweiten Netzwerktreffen sind für euch kostenlos.</p>
      `,
        ] as string[],
      },
      {
        title: 'Wann und wo finden die nächsten Netzwerktreffen statt?',
        content: [
          `
        <p>Über die Termine der Netzwerktreffen werdet ihr über den Signal-Chat informiert. Zudem gibt es einen Link zu einem Jahresplan. Den Link findet ihr in der Gruppenbeschreibung der Signalgruppe.</p>
      `,
        ] as string[],
      },
      {
        title: 'Wie und wo kann ich mich für ein Netzwerktreffen anmelden?',
        content: [
          `
        <p>Über die genauen Anmeldebedingungen der Netzwerktreffen informieren wir euch über Signal und E-Mail rechtzeitig.</p>
      `,
        ] as string[],
      },
      {
        header: 'Zertifikate für meinen Lebenslauf',
        title:
          'Welche Zertifikate kann ich für mein Ehrenamt erhalten und an wen wende ich mich, wenn ich ein Zertifikat benötige?',
        content: [
          `
        <p>Nach der kostenlosen Ausbildung und 12 Einsätzen erhältst du ein Zertifikat. Dieses bekommst du, indem du uns über Signal oder E-Mail (RealTalk@drk.de) schreibst. Das Begleitteam erstellt dann dein Zertifikat und sendet es dir zu. Solltest du zu einem späteren Zeitpunkt ein weiteres Zertifikat über deine geleistete Arbeit, z.B. wie viele Schichten du hattest, für deinen Lebenslauf benötigen, komm gerne auf uns zu.</p>
      `,
        ] as string[],
      },
      {
        header: 'Beendigung oder Pause vom Ehrenamt',
        title:
          'Ich möchte mein Ehrenamt beenden oder eine Pause machen. An wen kann ich mich wenden?',
        content: [
          `
        <p>Wenn du dein Ehrenamt nicht mehr weitermachen möchtest, lass es uns bitte wissen. Wir freuen uns, wenn wir ein Abschlussgespräch mit dir führen können. Es ist vollkommen okay, wenn du dein Ehrenamt beenden musst – es gibt viele Gründe dafür. Es ist auch kein Problem, eine Pause einzulegen und später wieder zurückzukommen. Sei einfach ehrlich mit uns, damit wir dich nicht mit weiteren Infos stören.</p>
      `,
        ] as string[],
      },
    ],
  },
}

export const admisStatisticsPageContent = {
  header: {
    title: 'Statistik',
    subtitle: '',
  },
  body: [
    {
      title: 'Chat',
      line1: 'Anzahl Chats Total',
      line2: 'Anzahl der Chats im letzten Monat',
      line3: 'Beratungszeit Total',
    },
  ],
}

export const supportPageContent = {
  header: {
    title: 'Ich brauche Support',
    subtitle:
      'Als Peer kannst du in der Beratung auf schwierige und belastende Situationen stoßen. Diese können sowohl für die Person, die Hilfe sucht, als auch für dich emotional herausfordernd sein. Es ist wichtig, ruhig zu bleiben, um gut zu helfen und gleichzeitig auf deine eigene Grenzen zu achten. Dieser Leitfaden gibt dir Anweisungen, wie du in einer für dich schwierigen Situationen reagieren kannst.',
  },
  body: {
    accordions: [
      {
        title: '1. Ruhig bleiben',
        content: [
          `
          <p>Diese Atemübung hilft:</p>
          <ul>
          <li>Tief einatmen und bis vier zählen.</li>
          <br/>
          <li>Atem kurz anhalten.</li>
          <br/>
          <li>Langsam bis vier ausatmen.</li>
          <br/>
          <li>Wiederhole das 3 mal</li>
          </>
`,
        ] as string[],
      },
      {
        title: '2. Bewertung der Situation',
        content: [
          `
          <p>Stell dir die folgenden Fragen:</p>
          <ul>
          <li>Wie „kritisch“ wirkt die Anfrage auf mich wenn die Skala 1 – 5 ist? </li>
          <br/>
          <li>Was braucht die ratsuchende Person jetzt? </li>
          <br/>
          <li>Was brauche ich? </li>
          <br/>
          </ul>
          `,
        ] as string[],
      },
      {
        title: '3. In Kontakt bleiben',
        content: [
          `
          <p>Stelle mehr Fragen aus den Textbausteinen <a target="_blank" href="/chatGuide">„mehr über das Problem herausfinden“</a> oder informiere den Ratsuchenden das du <a target="_blank" href="/chatGuide">mehr Zeit brauchst</a>, um zu antworten.</p>
          `,
        ] as string[],
      },
      {
        title: '4. Grenzen erkennen',
        content: [
          `
          <p>Du bist keine Psychologin: Deine Aufgabe ist es nicht, die Probleme zu lösen, sondern zuzuhören und bei Bedarf weiterzuleiten. Fühlst du dich unwohl dabei?</p>
          `,
        ] as string[],
      },
      {
        title: '5. Kontakt zum Peerbegleiter*in',
        content: [
          `
          <p>Nimm Kontakt auf, indem du an deine Peerbegleitung über Signal eine Nachricht schreibst, dass du Hilfe brauchst. Wir sind sofort für dich da.</p>
          `,
        ] as string[],
      },
      {
        title: '6. Ratsuchende Person informieren',
        content: [
          `
          <p>Mehr dazu findest du in den Textbausteinen <a target="_blank" href='/chatGuide'>„Notfall“</a>.</p>
          `,
        ] as string[],
      },
    ],
  },
}
