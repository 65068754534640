import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { AuthContext } from './context/loginContext'
import ChatDataProvider from './context/chatContext'

interface ProtectedRouteProps {
  children: React.ReactNode
}

const ProtectedRoute = ({ children }: ProtectedRouteProps): JSX.Element | null => {
  const { getLastAuthPeer } = useContext(AuthContext)

  if (!children) return null

  if (!getLastAuthPeer()) {
    // Redirect to the login page if user is not authenticated
    return <Navigate to="/login" replace />
  }

  return (
    <>
      <ChatDataProvider>{children}</ChatDataProvider>
    </>
  )
}

export default ProtectedRoute
