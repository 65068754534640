import { useTranslation } from 'react-i18next'
import { Dialog, Box, Button, Typography } from '@mui/material'

import { colors } from '../../theme'

type Props = {
  openAcceptDialog: boolean
  handleCloseAcceptDialog: () => void
  acceptChat: () => void
  declineChat: () => void
}

const AcceptChatDialog = ({
  openAcceptDialog,
  handleCloseAcceptDialog,
  acceptChat,
  declineChat,
}: Props): React.ReactElement => {
  const { t } = useTranslation('base')

  const handleAcceptNewChat = () => {
    handleCloseAcceptDialog()
    acceptChat()
  }

  const onCloseDialog = (event: HTMLInputElement, reason: string) => {
    if (reason && reason == 'backdropClick') return
    handleCloseAcceptDialog()
  }

  return (
    <Dialog
      open={openAcceptDialog}
      onClose={onCloseDialog}
      PaperProps={{ sx: { borderRadius: '12px' } }}
    >
      <Box
        borderRadius="25%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '480px',
          p: '36px',
        }}
      >
        <Typography variant="h5" fontWeight="bold" textAlign={'center'}>
          {t('acceptChatDialogTittle')}
        </Typography>

        <Button
          variant="contained"
          onClick={() => handleAcceptNewChat()}
          sx={{
            background: colors.drkRot,
            width: '214px',
            height: '56px',
            borderRadius: '56px',
            mt: '32px',
            textTransform: 'none',
          }}
        >
          <Typography
            variant="body1"
            fontSize="18px"
            lineHeight="18px"
            fontWeight={700}
            color="primary.light"
          >
            {t('acceptChatButtonTitle')}
          </Typography>
        </Button>

        <Button
          variant="text"
          onClick={() => declineChat()}
          sx={{
            width: '214px',
            borderRadius: '24px',
            mt: '32px',
            textTransform: 'none',
            boxShadow: 'none',
          }}
        >
          <Typography variant="body1" fontSize="18px" fontWeight={500} color="primary.dark">
            {t('declineChatButtonTitle')}
          </Typography>
        </Button>
      </Box>
    </Dialog>
  )
}

export default AcceptChatDialog
