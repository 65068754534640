import { createTheme } from '@mui/material/styles'
import { grey, indigo } from '@mui/material/colors'

export const theme = createTheme({
  palette: {
    primary: {
      light: grey[100],
      dark: '#333333',
      contrastText: '#fff',
      main: grey[400],
    },
    secondary: {
      main: grey[500],
    },
  },
  typography: {
    fontFamily: ['Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#6b6b6b #2b2b2b',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            backgroundColor: '#554F4A',
            minHeight: 24,
            // borderLeft: '3px solid transparent',
            // borderRight: '3px solid transparent',
            // borderRadius: '9px',
            backgroundClip: 'content-box',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#2b2b2b',
          },
        },
      },
    },
  },
})

export const colors = {
  iconLight: grey[100],
  blueButton: indigo[500],
  drkRot: '#E60005',
}
