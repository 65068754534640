const base = {
  helloWorld: 'Hello Red Cross',
  topBarTitle: 'RealTalk',
  logOutTitle: 'Log out',
  copyToConsoleMessage: 'Text in die Zwischenablage kopiert',

  // accept chat
  newChatAlertLabel: 'Neue Chat-Anfrage',
  acceptChatDialogTittle: 'Möchtest du die neue Chat-Anfrage annehmen?',
  acceptChatButtonTitle: 'Jetzt Chat starten',
  skipChatButtonTitle: 'Chat ablehnen',
  chatCardIdChatIdTitle: 'Chat-ID',
  declineChatButtonTitle: 'Chat ablehnen',

  // chat
  messageInputPlaceholder: 'Nachricht schreiben',
  chatStartTimeLabel: `Chat wurde um {{time}} Uhr verbunden`,
  noActiveConversationTitle: 'Keine aktiven Chats',
  noActiveConversationArchivedTitle: 'Keine Chats',

  // report chat
  reporInputLabel: 'Kommentar schreiben',
  sendFeedbackButton: 'Feeback senden',
  reportDialogChatTitle: 'Möchtest du diese Person melden?',
  reportDialogChatTitleHelper:
    'Bitte überlege gut, ob du jemanden meldest. Nach drei Meldungen von Peers wird die Person für den Chat geblockt.',
  reportDialogChatConfirmButtonText: 'Person melden',
  reportDialogChatBackButtonChatText: 'Zurück zum Chat',
  reportUserDialogButtonText: 'Person melden',

  // close chat
  closeChatDialoglTitle: 'Bist du sicher dass du den Chat beenden möchtest?',
  closeChatDialogTitleHelper: 'Diese Entscheidung kann nicht rückgängig gemacht werden',
  closeChatDialogConfirmButtonText: 'Jetzt Chat beenden',
  closeChatDialogBackToChatButtonText: 'Zurück zum Chat',
  closeChatButtonText: 'Chat beenden',
  copyConversationIdButtonText: 'Chat ID kopieren',

  // chatGuide
  linkToHelp: 'Alle Beratungsangebote',
  linkToFurtherAdvisoryTitle: 'Weitere Textbausteine',
  chatGuideTittle: 'Textbausteine für den Chat',
  chatGuideBoxesTitle: 'Weiterführende Beratungsangebote',

  // admin
  searchFilterInputPlaceholder: 'Nach Name oder ID filtern',

  // admin conversation ifno page
  headerText: 'Informationen zur Administrator konversation',
  titleText: 'Konversations info',
  conversationIdText: 'Konversations ID',
  peerNameText: 'Peername',
  phoneNumberText: 'Telefonnummer',
  revealBtnText: 'telefonnummer',

  // logout
  logoutButtonText: 'Abmelden',
  logoutDialogTitle: 'Bist du sicher, dass du dich abmelden möchstest?',
  logoutDialogTitleHelper: 'Alle aktiven Chats werden beendet.',
  logoutDialogConfirmButtonText: 'Jetzt abmelden',
  logoutDialogBackButtonText: 'Zurück zum Chat',

  // login
  nameFieldTitle: 'Benutzername',
  passwordFieldTitle: 'Passwort',
  submitLoginButtonText: 'Jetzt anmelden',

  // support
  supportButtonText: 'Ich brauche Support',
}

export default base
