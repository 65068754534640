import { Grid } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import ChatGuide from '../chatGuide/ChatGuide'
import { useChat } from '../../context/chatContext'
import { AuthContext } from '../../context/loginContext'

import Chat from './Chat'
import ChatList from './ChatList'

const ChatLayout = (): React.ReactElement => {
  const { setIsArchivedChats } = useChat()
  const navigate = useNavigate()

  const { isAdmin, conversationId } = useContext(AuthContext)

  useEffect(() => {
    setIsArchivedChats(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isAdmin && !!conversationId) {
      navigate('/adminConversationInfo')
    } else if (isAdmin || conversationId === undefined) {
      navigate('/adminStatistics')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin])

  return (
    <Grid
      container
      ml={7}
      sx={{
        maxWidth: 'calc(100% - 56px)',
        height: 'calc(100vh - 64px)',
        '*::-webkit-scrollbar': {
          width: '5px',
        },
        '*::-webkit-scrollbar-track': {
          background: 'white',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#554F4A',
          borderRadius: '2px',
        },
      }}
    >
      <Grid
        item
        xs={3}
        md={3}
        xl={3}
        sx={{
          maxHeight: '100%',
          overflow: 'auto',
          borderRight: 'solid 1px #D9D9D9',
          maxWidth: 'calc(100% - 56px)',
          height: 'calc(100vh - 64px)',
          '*::-webkit-scrollbar': {
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            background: 'white',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#554F4A',
            borderRadius: '2px',
          },
        }}
      >
        <ChatList />
      </Grid>
      <Grid item xs={6} md={6} xl={6} sx={{ height: '100%' }}>
        <Chat />
      </Grid>
      <Grid
        item
        xs={3}
        md={3}
        xl={3}
        sx={{
          background: '#FBFBFB',
          borderLeft: 'solid 1px #D9D9D9',
          maxHeight: '100%',
          overflow: 'auto',
        }}
      >
        <ChatGuide />
      </Grid>
    </Grid>
  )
}

export default ChatLayout
