import React, { createContext, useContext, useEffect, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import uuid from 'react-uuid'

import chatService from '../services/chat'
import NotificationSound from '../assets/mixkit_fairy_message_notification.wav'
import messageNotification from '../assets/Message notification.mp3'
import {
  Conversation,
  ConversationStatus,
  LastMessage,
  MessageSource,
  MessageType,
} from '../models/conversation'
import { NewChatNotification } from '../models/notification'

import { AuthContext } from './loginContext'
import { NotificationContext } from './notificationContext'
import { useSelectedConversations } from './useSelectedConversations'

const notificationSound = new Audio(NotificationSound)
const messageAudio = new Audio(messageNotification)

type ChatContext = {
  peersMessageBody: string
  setPeersMessageBody: (body: string) => void
  handleSendPeersMessage: () => void
  handleCloseConversation: () => void
  activeConversations: Conversation[]
  setSelectedConversationId: (id: string) => void
  selectedConversationId: string | null
  selectedConversationMessages: MessageType[] | null
  handleSelectConversation: (id: string) => void
  updatedConversationIds: string[]
  isArchivedChats: boolean
  setIsArchivedChats: (body: boolean) => void
  noActiveConversations: boolean
  setSearchValue: (body: string) => void
  handleReportUser: () => void
  getStatistics: () => void
  // Statistics types - will be provided later once we have all texts
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  statistics: any
  searchValue: string
  handleSearchConversations: () => void
  setSelectedConversationMessages: (body: MessageType[]) => void
  isLoadingChats: boolean
  setIsLoadingChats: (body: boolean) => void
  isLoadingMessages: boolean
  setIsLoadingMessages: (body: boolean) => void
  setSelectedArchivedConversationId: (body: string) => void
  handleSelectArchivedConversation: (id: string) => void
  selectedArchivedConversationId: string
  conversationsFoundByConversationId: Conversation
  handleFoundConversationNumberByConversationId: (conversationId: string) => void
}

const ChatData = createContext<ChatContext>({
  peersMessageBody: '',
  setPeersMessageBody() {},
  handleSendPeersMessage() {},
  handleCloseConversation() {},
  activeConversations: [],
  setSelectedConversationId() {},
  setSelectedConversationMessages() {},
  selectedConversationId: null,
  selectedConversationMessages: null,
  handleSelectConversation() {},
  updatedConversationIds: [],
  isArchivedChats: false,
  setIsArchivedChats() {},
  noActiveConversations: false,
  setSearchValue() {},
  handleReportUser() {},
  getStatistics() {},
  statistics: null,
  searchValue: '',
  handleSearchConversations() {},
  isLoadingChats: false,
  setIsLoadingChats() {},
  isLoadingMessages: false,
  setIsLoadingMessages() {},
  setSelectedArchivedConversationId() {},
  handleSelectArchivedConversation() {},
  selectedArchivedConversationId: '',
  conversationsFoundByConversationId: {} as Conversation,
  handleFoundConversationNumberByConversationId() {},
})

type Props = {
  children: React.ReactNode
}

const ChatDataProvider = ({ children }: Props): React.ReactElement => {
  const cognitoPoolId = process.env.REACT_APP_COGNITO_POOL_CLIENT_ID
  const lastAuthPeer = localStorage.getItem(
    `CognitoIdentityServiceProvider.${cognitoPoolId}.LastAuthUser`
  )
  const loginPeerToken = localStorage.getItem(
    `CognitoIdentityServiceProvider.${cognitoPoolId}.${lastAuthPeer}.idToken`
  )
  const [searchValue, setSearchValue] = useState('')
  const [updatedConversationIds, setUpdatedConversationIds] = useState<string[]>([])
  const [conversationsFoundByConversationId, setConversationsFoundByConversationId] =
    useState<Conversation>({} as Conversation)

  const [activeConversations, setActiveConversations] = useState<Conversation[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [statistics, setStatistics] = useState<any | null>(null)
  const [peersMessageBody, setPeersMessageBody] = useState<string>('')
  const noActiveConversations = activeConversations?.length === 0
  const [isLoadingChats, setIsLoadingChats] = useState(false)
  const { user, isAdmin, currentSession } = useContext(AuthContext)
  const { notifications, setNotifications, notificationAccepted, setNotificationAccepted } =
    useContext(NotificationContext)
  const {
    selectedConversationId,
    selectedArchivedConversationId,
    setSelectedConversationId,
    setSelectedArchivedConversationId,
    isLoadingMessages,
    setIsLoadingMessages,
    selectedConversationMessages,
    setSelectedConversationMessages,
    isArchivedChats,
    setIsArchivedChats,
  } = useSelectedConversations(currentSession)

  useEffect(() => {
    if (notificationAccepted) {
      setSelectedConversationId(activeConversations[0]?.conversationId)
      setNotificationAccepted(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversations])

  const selectedConversationsType = isArchivedChats
    ? ConversationStatus.INACTIVE
    : ConversationStatus.ACTIVE

  const outgoingPeersMessage = {
    // create ENUM
    action: 'sendMessage',
    body: peersMessageBody,
    conversationId: selectedConversationId,
    token: `Bearer ${loginPeerToken}`,
  }

  const currentTime = Math.floor(Date.now() / 1000)
  const outgoingPeersMessageFormated = {
    action: 'sendMessage',
    body: {
      message: peersMessageBody,
      messageId: uuid(),
      createdAt: currentTime,
      source: MessageSource.PEER,
    },
    conversationId: selectedConversationId,
  }

  const handleUpdatePreviewMessage = (lastMessage: LastMessage) => {
    const updateConversationByNewMessage = activeConversations.map((coversation: Conversation) =>
      lastMessage?.conversationId === coversation?.conversationId
        ? {
            ...coversation,
            previewMessage: lastMessage?.data?.message,
            updatedAt: lastMessage?.data?.createdAt,
          }
        : coversation
    )
    setActiveConversations(
      updateConversationByNewMessage.sort(
        (a: Conversation, b: Conversation) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      )
    )
  }

  const handleIncommingMessage = () => {
    if (lastJsonMessage?.action === 'chatClosed') {
      if (lastJsonMessage?.conversationId === selectedConversationId) {
        setSelectedConversationMessages([])
        handleSelectConversation('')
      }
      getConversations()

      return
    }
    // have to be any by default cause notification can be different by type
    if (lastJsonMessage?.action === 'message' || lastJsonMessage?.action === 'templateMessage') {
      if (lastJsonMessage.conversationId === selectedConversationId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setSelectedConversationMessages((prev: any) => [...prev, lastJsonMessage.data])
      } else {
        messageAudio.play()
      }
      handleUpdatePreviewMessage(lastJsonMessage)
      !updatedConversationIds?.includes(lastJsonMessage.conversationId) &&
        setUpdatedConversationIds([...updatedConversationIds, lastJsonMessage.conversationId])
    }

    if (lastJsonMessage?.action === 'notification') {
      setNotifications((prev: NewChatNotification[]) => [
        ...prev,
        {
          payload: lastJsonMessage.payload as string,
          notificationId: lastJsonMessage.notificationId as string,
        },
      ])
      notificationSound.play()
    }

    if (lastJsonMessage?.action === 'notificationExpired') {
      const filteredNotifications = notifications.filter(
        (notification: NewChatNotification) =>
          notification.notificationId !== lastJsonMessage.notificationId
      )

      setNotifications(filteredNotifications)
    }
  }

  const handleSendPeersMessage = () => {
    sendMessage(JSON.stringify(outgoingPeersMessage))
    handleUpdatePreviewMessage({
      data: outgoingPeersMessageFormated.body,
      conversationId: selectedConversationId,
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSelectedConversationMessages((prev: any) => prev.concat(outgoingPeersMessageFormated.body))
    setPeersMessageBody('')
  }

  const getConversations = async () => {
    // for now, dont fetch for admin
    const session = await currentSession()
    const authHeader = { Authorization: 'Bearer ' + session?.idToken.jwtToken }

    chatService
      .getConversations(authHeader, selectedConversationsType, isAdmin ? '' : lastAuthPeer)
      .then((res) => {
        if (!res?.data?.data?.conversations.length) {
          handleSelectConversation('')
        }
        // will be handled in BE, workaround for now
        if (selectedConversationsType === ConversationStatus.INACTIVE) {
          const inactiveConversations = res?.data?.data?.conversations
            .filter((conversation: Conversation) => {
              return conversation.status === 'INACTIVE'
            })
            .sort((a: Conversation, b: Conversation) => b.createdAt - a.createdAt)
          setActiveConversations(inactiveConversations)
        } else {
          setActiveConversations(
            res?.data?.data?.conversations.sort(
              (a: Conversation, b: Conversation) =>
                new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
            )
          )
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
      .finally(() => {
        setIsLoadingChats(false)
      })
  }

  const handleSelectArchivedConversation = (id: string) => {
    setSelectedArchivedConversationId(id)
    id && localStorage.setItem('selectedArchivedLocalConversationId', id)
    setUpdatedConversationIds(
      updatedConversationIds?.filter((updatedId: string) => updatedId !== id)
    )
  }

  const handleFoundConversationByConversationId = async (conversationId: string) => {
    const session = await currentSession()
    const authHeader = { Authorization: 'Bearer ' + session?.idToken.jwtToken }

    chatService
      .searchConversations(authHeader, conversationId)
      .then((res) => setConversationsFoundByConversationId(res?.data?.data?.conversations[0]))
      .catch((err) => console.error(err))
  }

  const handleSelectConversation = (id: string) => {
    setSelectedConversationId(id)
    localStorage.setItem('selectedLocalConversationId', JSON.stringify(!id ? null : id))
    setUpdatedConversationIds(
      updatedConversationIds?.filter((updatedId: string) => updatedId !== id)
    )
  }

  const handleCloseConversation = async () => {
    const session = await currentSession()
    const authHeader = { Authorization: 'Bearer ' + session?.idToken.jwtToken }

    selectedConversationId &&
      chatService
        .closeConversation(selectedConversationId, authHeader)
        .then(() => {
          handleSelectConversation('')
          getConversations()
        })
        .catch((err) => console.error(err))
        .finally(() => {})
  }

  const handleSearchConversations = async () => {
    const session = await currentSession()
    const authHeader = { Authorization: 'Bearer ' + session?.idToken.jwtToken }

    chatService
      .searchConversations(authHeader, searchValue)
      .then((res) =>
        setActiveConversations(
          res?.data?.data?.conversations.sort(
            (a: Conversation, b: Conversation) => b.createdAt - a.createdAt
          )
        )
      )
      .catch((err) => console.error(err))
  }

  const handleReportUser = async () => {
    const session = await currentSession()
    const authHeader = { Authorization: 'Bearer ' + session?.idToken.jwtToken }

    chatService
      .reportUser(selectedConversationId, authHeader)
      .then(() => {
        activeConversations && setSelectedConversationId(activeConversations[0]?.conversationId)
        setSelectedConversationMessages([])
        getConversations()
      })
      .catch((err) => console.error(err))
  }

  const getStatistics = async () => {
    const session = await currentSession()
    const authHeader = { Authorization: 'Bearer ' + session?.idToken.jwtToken }

    chatService
      .getStatistics(authHeader)
      .then((res) => setStatistics(res?.data?.data))
      .catch((err) => console.error(err))
      .finally()
  }

  //  ->>>>>>>>>>>>>>>>>>>> USEWEBSCOKET ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const { sendMessage, lastMessage, lastJsonMessage } = useWebSocket(
    process.env.REACT_APP_WEBSOCKET_BASE_URL + `/?token=${loginPeerToken}`,
    {
      shouldReconnect: () => true,
    }
  )

  useEffect(() => {
    const storedValue = localStorage.getItem('selectedLocalConversationId')
    storedValue
      ? setSelectedConversationId(storedValue ? JSON.parse(storedValue) : null)
      : activeConversations && handleSelectConversation(activeConversations[0]?.conversationId)
    setSelectedArchivedConversationId(
      localStorage.getItem('selectedArchivedLocalConversationId') || ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getConversations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchivedChats])

  useEffect(() => {
    handleIncommingMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage, lastJsonMessage])

  useEffect(() => {
    isAdmin && getConversations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    getConversations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  // keep this logs for debugging in dev ENV

  // console.log('connectionStatus', connectionStatus)
  // console.log('LAST JSON MESSGAE =>>>>>>>>>>>>>>>>>>>>', lastJsonMessage)
  // console.log('new notification', lastJsonMessage)
  // console.log('peersMSG BODY ', peersMessageBody)
  // console.log('acceptNotificatinHeader', authHeader)
  // console.log('selectedConversationId', selectedConversationId)
  // console.log('CHAT CONTEXT - CONVERSATIONS', conversations)
  // console.log('local st token:', localStoragePeerToken)
  // console.log('messageHistory', messageHistory)
  // console.log('activeConversations', activeConversations)
  // console.log('selectedConversationMessages', selectedConversationMessages)
  // console.log('selectedLocalConversationId', selectedLocalConversationId)
  // console.log(localStorage.removeItem('user'))
  // console.log('updatedConversationIds', updatedConversationIds)
  // console.log('selectedConversationsType', selectedConversationsType)
  // console.log('isArchivedChats', isArchivedChats)
  // console.log('notifications', notifications)

  //  ->>>>>>>>>>>>>>>>>>>> USEWEBSCOKET ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const providerValue: ChatContext = {
    activeConversations,
    // acceptNotification: acceptNotification,
    // notifications: notifications,
    peersMessageBody,
    setPeersMessageBody,
    handleSendPeersMessage,
    handleCloseConversation,
    setSelectedConversationId,
    selectedConversationId,
    selectedConversationMessages,
    handleSelectConversation,
    updatedConversationIds,
    isArchivedChats,
    setIsArchivedChats,
    noActiveConversations,
    setSearchValue,
    handleReportUser,
    getStatistics,
    statistics,
    searchValue,
    handleSearchConversations,
    setSelectedConversationMessages,
    isLoadingChats,
    setIsLoadingChats,
    isLoadingMessages,
    setIsLoadingMessages,
    setSelectedArchivedConversationId,
    handleSelectArchivedConversation,
    selectedArchivedConversationId,
    conversationsFoundByConversationId,
    handleFoundConversationNumberByConversationId: handleFoundConversationByConversationId,
  }

  return <ChatData.Provider value={providerValue}>{children}</ChatData.Provider>
}

const useChat = (): ChatContext => useContext<ChatContext>(ChatData)

export default ChatDataProvider
export { ChatData, useChat }
