import { useContext, useEffect, useState } from 'react'
import { TextField, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useChat } from '../../context/chatContext'
import { ReactComponent as IconSearchFilter } from '../../assets/Icon_SearchFilter.svg'
import { AuthContext } from '../../context/loginContext'
import { NotificationContext } from '../../context/notificationContext'
import { Conversation } from '../../models/conversation'
import { OnNotificationAction } from '../../models/notification'

import AcceptChatCard from './AcceptChatCard'
import ChatCard from './ChatCard'
import NoActiveChatCard from './NoActiveChatCard'

const ChatList = (): React.ReactElement => {
  const { t } = useTranslation('base')
  const {
    activeConversations,
    noActiveConversations,
    setSearchValue,
    searchValue,
    handleSearchConversations,
    isLoadingChats,
    isArchivedChats,
    setIsLoadingMessages,
    isLoadingMessages,
  } = useChat()

  const { notifications, acceptNotification } = useContext(NotificationContext)
  const { isAdmin } = useContext(AuthContext)

  const [showNotificationCard, setShowNotificationCard] = useState(false)

  useEffect(() => {
    notifications.length > 0 && setShowNotificationCard(true)
  }, [notifications])

  const onAcceptNotification = () => {
    acceptNotification(OnNotificationAction.ACCEPT)
    setShowNotificationCard(false)
    setIsLoadingMessages(true)
  }

  const onDeclineNotification = () => {
    acceptNotification(OnNotificationAction.DECLINE)
    setShowNotificationCard(false)
  }

  useEffect(() => {
    isAdmin && handleSearchConversations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const renderSearchField = () => {
    return (
      <Box
        sx={{
          p: 2,
          backgroundColor: '#333333',
          opacity: '80%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
            alignItems: 'center',
            borderRadius: 2,
          }}
        >
          <TextField
            name="searchFilter"
            size="small"
            fullWidth
            placeholder={t('searchFilterInputPlaceholder')}
            onChange={(e) => setSearchValue(e.target.value)}
            inputProps={{
              onKeyPress: (event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              },
            }}
            sx={{
              backgroundColor: '#FFFFFF',
              alignItems: 'center',
              height: '22px',
              background: '#FFFFFF',
              mb: 2,
              borderRadius: '8px',
              '& fieldset': { border: 'none' },
              fontWeight: '15px',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <IconSearchFilter stroke="#FDE5E6" />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {isAdmin && renderSearchField()}
      {!isArchivedChats && notifications.length > 0 && showNotificationCard && (
        <AcceptChatCard
          onAcceptNotification={onAcceptNotification}
          onDeclineNotification={onDeclineNotification}
        />
      )}
      {!isLoadingChats &&
        activeConversations?.map((conversation: Conversation) => (
          <ChatCard key={conversation?.conversationId} conversation={conversation} />
        ))}
      {!isAdmin && noActiveConversations && !isLoadingMessages && <NoActiveChatCard />}
    </Box>
  )
}

export default ChatList
