import { Conversation } from '../models/conversation'

const getTimeFormatForConversation = (updatedAt: number, craetedAt: number) => {
  // get updatedAt and convert to minutes/seconds -> if more than 1 hour show date
  const currentTime = Math.floor(Date.now() / 1000)
  const dateObj = new Date(craetedAt * 1000)

  const time = currentTime - updatedAt

  // less than minute -> few seconds ago
  // more than hour -> date
  // between hours and 1 minute -> x minutes ago
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const year = dateObj.getFullYear()
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2) // Months are zero-based
  const day = ('0' + dateObj.getDate()).slice(-2)

  if (minutes < 1 && hours === 0) return 'a few seconds ago'
  if (hours > 0) return `${day}/${month}/${year}`
  return `${minutes} minutes ago`
}
export const conversationActiveTime = (conversation: Conversation) =>
  getTimeFormatForConversation(conversation.updatedAt, conversation.createdAt)
