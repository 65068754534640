import { Box, Container } from '@mui/material'

import StaticPageHeader from '../StaticPageHeader'
import { supportPageContent } from '../../../i18n/locales/en/textFields'
import StaticPageBodyExtendedList from '../StaticPageBodyExtendedList'

const SupportPage = () => {
  return (
    <Box sx={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
      <Box
        sx={{
          background: '#fff',
          maxHeight: '100%',
          overflow: 'auto',
        }}
      >
        <Container maxWidth={'md'}>
          <StaticPageHeader
            title={supportPageContent.header.title}
            subtitle={supportPageContent.header.subtitle}
          ></StaticPageHeader>
        </Container>
      </Box>
      <Box>
        <Container maxWidth={'md'}>
          <StaticPageBodyExtendedList staticPageContent={supportPageContent} />
        </Container>
      </Box>
    </Box>
  )
}

export default SupportPage
