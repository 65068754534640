import { MessageType } from './conversation'

export type Notification = {
  notificationId: string
  peerName: string
  declinedPeers: string[]
  messages: MessageType[]
  ttl: number
  number: string
}

export interface NewChatNotification {
  notificationId: string
  payload: string
}

export enum OnNotificationAction {
  ACCEPT = 'ACCEPT',
  DECLINE = 'DECLINE',
}

export interface NotificationHeaders {
  Authorization: string
}
