import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useChat } from '../../context/chatContext'

const NoActiveChatCard = (): React.ReactElement => {
  const { t } = useTranslation('base')

  const { isArchivedChats } = useChat()

  return (
    <Box
      sx={{
        width: '100%',
        height: '48px',
        background: '#FBFBFB',
        display: 'flex',
        alignItems: 'center',
        pl: 4,
        borderBottom: 'solid 1px #D9D9D9',
      }}
    >
      {!isArchivedChats ? (
        <Typography align="left" fontSize={'16px'} fontWeight={'500'} lineHeight={'22px'}>
          {t('noActiveConversationTitle')}
        </Typography>
      ) : (
        <Typography align="left" fontSize={'16px'} fontWeight={'500'} lineHeight={'22px'}>
          {t('noActiveConversationArchivedTitle')}
        </Typography>
      )}
    </Box>
  )
}

export default NoActiveChatCard
