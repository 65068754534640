import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ReactHtmlParser from 'html-react-parser'

import arrowIcon from '../../assets/Icon_arrow_gray.svg'

const AccordionIcon = () => {
  return <img src={arrowIcon} width={16} height={16} />
}

type StaticPageContent = {
  header: {
    title: string
    subtitle: string
  }
  body: {
    accordions: {
      header?: string
      title: string
      content: string[]
    }[]
  }
}

const StaticPageBodyExtendedList = ({
  staticPageContent,
}: {
  staticPageContent: StaticPageContent
}): React.ReactElement => {
  return (
    <Box sx={{ paddingTop: '48px', pb: '48px' }}>
      {staticPageContent.body.accordions.map((accordion) => (
        <>
          <Typography variant="h6" fontWeight={700} mb={2}>
            {accordion.header}{' '}
          </Typography>
          <Accordion
            key={accordion.title}
            disableGutters={true}
            sx={{
              borderRadius: '8px !important',
              border: 'solid 1px #D9D9D9',
              '&:before': {
                backgroundColor: 'transparent !important',
              },
              marginBottom: '12px',
              boxShadow: 'none',
            }}
          >
            <AccordionSummary expandIcon={<AccordionIcon />}>
              <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'} color={'#2276D0'}>
                {accordion.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {accordion.content.map((content, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'start' }}>
                  <Box
                    fontSize={'18px'}
                    fontWeight={400}
                    lineHeight={'20px'}
                    sx={{ marginTop: '-18px', lineHeight: '26px' }}
                  >
                    {ReactHtmlParser(content.replace('<br />', ''))}
                  </Box>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </>
      ))}
    </Box>
  )
}

export default StaticPageBodyExtendedList
