import { NavLink, useLocation } from 'react-router-dom'
import { Drawer, Box, List, ListItem, ListItemButton, Divider, Tooltip } from '@mui/material'
import { useContext } from 'react'

import { ReactComponent as ChatIcon } from '../../assets/Icon_Chat.svg'
import { ReactComponent as ArchiveIcon } from '../../assets/Icon_Archive.svg'
import { ReactComponent as LeitfadeIcon } from '../../assets/Icon_Leitfaden.svg'
import { ReactComponent as MapIcon } from '../../assets/Icon_Map.svg'
import { ReactComponent as InfoIcon } from '../../assets/Icon_Infos.svg'
import { ReactComponent as StatsIcon } from '../../assets/Icon_Stats.svg'
import { sideIconButton } from '../LayoutStyle'
import { theme } from '../../theme'
import routes from '../../constants/routes'
import { AuthContext } from '../../context/loginContext'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'

const SideBar = (): React.ReactElement => {
  const { isAdmin, conversationId } = useContext(AuthContext)
  const location = useLocation()

  const isActiveRoute = (route: string): boolean => {
    return location.pathname === route
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: '48px',
        p: 0,
        '& .MuiDrawer-paper': { borderWidth: 0 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: theme.palette.primary.dark,
          height: '100vh',
          mt: 8,
        }}
      >
        <List color="primary">
          {isAdmin ? (
            <>
              <ListItem disablePadding>
                <Tooltip placement={'right'} title="Statistik">
                  <ListItemButton
                    component={NavLink}
                    to={routes.adminStatistics}
                    sx={sideIconButton}
                  >
                    <StatsIcon
                      style={{
                        stroke: isActiveRoute(routes.adminStatistics) ? '#EB8264' : '#F2F2F2',
                      }}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>

              {conversationId && (
                <ListItem disablePadding>
                  <Tooltip placement={'right'} title="ConversationInfo">
                    <ListItemButton
                      component={NavLink}
                      to={routes.adminConversationInfo}
                      sx={sideIconButton}
                    >
                      <AccountBoxOutlinedIcon
                        style={{
                          fontSize: '2rem',
                          stroke: isActiveRoute(routes.adminConversationInfo)
                            ? '#EB8264'
                            : '#F2F2F2',
                        }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              )}
            </>
          ) : (
            <ListItem disablePadding>
              <Tooltip placement={'right'} title="Chat">
                <ListItemButton component={NavLink} to={routes.chat} sx={sideIconButton}>
                  <ChatIcon
                    style={{
                      stroke: isActiveRoute(routes.chat) ? '#EB8264' : '#F2F2F2',
                    }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          )}

          <ListItem disablePadding>
            <Tooltip placement={'right'} title="Archiv">
              <ListItemButton component={NavLink} to={routes.archivedChats} sx={sideIconButton}>
                <ArchiveIcon
                  style={{
                    stroke: isActiveRoute(routes.archivedChats) ? '#EB8264' : '#F2F2F2',
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>

          <ListItem disablePadding>
            <Tooltip placement={'right'} title="Chat-Leitfaden">
              <ListItemButton component={NavLink} to={routes.chatGuide} sx={sideIconButton}>
                <LeitfadeIcon
                  style={{
                    stroke: isActiveRoute(routes.chatGuide) ? '#EB8264' : '#F2F2F2',
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>

          <ListItem disablePadding>
            <Tooltip placement={'right'} title="Weiterführende Beratungsangebote">
              <ListItemButton component={NavLink} to={routes.furtherAdvisory} sx={sideIconButton}>
                <MapIcon
                  style={{
                    stroke: isActiveRoute(routes.furtherAdvisory) ? '#EB8264' : '#F2F2F2',
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>

        <Divider />
        <List>
          <ListItem disablePadding>
            <Tooltip placement={'right'} title="FAQ">
              <ListItemButton component={NavLink} to={routes.help} sx={sideIconButton}>
                <InfoIcon
                  style={{
                    stroke: isActiveRoute(routes.help) ? '#EB8264' : '#F2F2F2',
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  )
}
export default SideBar
