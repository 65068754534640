import { useState } from 'react'
import { Alert, Box, Snackbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import copyIcon from '../../assets/Icon_Copy.svg'

type Props = {
  text: string
  type: string
}

const AccordionLine = ({ text, type }: Props) => {
  const { t } = useTranslation('base')
  const [isConversationIdCopied, setIsConversationIdCopied] = useState(false)
  return (
    <Box sx={{ display: 'flex', alignItems: 'start' }}>
      <img
        src={copyIcon}
        width={16}
        height={16}
        style={{ cursor: 'pointer', marginTop: '5px' }}
        onClick={() => {
          navigator.clipboard.writeText(text)
          setIsConversationIdCopied(true)
        }}
      />
      <Typography
        fontSize={type === 'sm' ? '15px' : '18px'}
        fontWeight={400}
        lineHeight={type === 'sm' ? '18px' : '26px'}
        sx={{ paddingLeft: '8px !important', paddingTop: '3px !important' }}
      >
        {text}
      </Typography>
      <Snackbar
        open={isConversationIdCopied}
        autoHideDuration={1500}
        onClose={() => setIsConversationIdCopied(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {t('copyToConsoleMessage')}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default AccordionLine
