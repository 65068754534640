import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'

import { colors } from '../../theme'
import RightArrowIcon from '../../assets/Icon_Chevron_Right.svg'
import { NotificationContext } from '../../context/notificationContext'

import AcceptChatDialog from './AcceptChatDialog'

type Props = {
  onAcceptNotification: () => void
  onDeclineNotification: () => void
}

const AcceptChatCard = ({ onAcceptNotification, onDeclineNotification }: Props) => {
  const { t } = useTranslation('base')

  const [openAcceptDialog, setOpenAcceptDialog] = useState<boolean>(false)
  const { notifications } = useContext(NotificationContext)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: colors.drkRot,
        color: 'white',
        p: 2,
        cursor: 'pointer',
      }}
      onClick={() => setOpenAcceptDialog(true)}
    >
      <Typography
        fontSize={'22px'}
        sx={{
          pl: 2,
          fontWeight: 'bold',
        }}
      >
        {notifications.length > 0 && notifications.length} {t('newChatAlertLabel')}
      </Typography>

      <Button
        sx={{
          color: 'white',
          fontWeight: 'bold',
        }}
        // onClick={() => setOpenAcceptDialog(true)}
      >
        <img src={RightArrowIcon} width={16} height={16} alt="DRK" />{' '}
      </Button>
      <AcceptChatDialog
        openAcceptDialog={openAcceptDialog}
        handleCloseAcceptDialog={() => setOpenAcceptDialog(false)}
        acceptChat={onAcceptNotification}
        declineChat={onDeclineNotification}
      />
    </Box>
  )
}
export default AcceptChatCard
