import { useState } from 'react'
import { Alert, Box, Snackbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import copyIcon from '../../assets/Icon_Copy.svg'

type Props = {
  title: string
  link: string
  line?: string | undefined
}

const ChatGuideBox = ({ title, line, link }: Props) => {
  const { t } = useTranslation('base')
  const [isConversationIdCopied, setIsConversationIdCopied] = useState(false)

  return (
    <Box
      sx={{
        padding: '12px',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        border: 'solid 1px #D9D9D9',
        borderRadius: '4px',
        marginBottom: '8px',
      }}
    >
      <Box sx={{ maxWidth: '90%' }}>
        <Typography
          sx={{ overflowWrap: 'break-word', paddingBottom: '6px' }}
          fontSize={'15px'}
          fontWeight={500}
          lineHeight={'18px'}
        >
          {title}
        </Typography>
        <Typography
          sx={{ overflowWrap: 'break-word', wordBreak: 'break-all' }}
          fontSize={'15px'}
          fontWeight={400}
          lineHeight={'18px'}
          color={'#2276D0'}
        >
          <Link style={{ color: '#2276D0' }} target="_blank" to={link}>
            {link}
          </Link>
        </Typography>
        {line && (
          <Typography
            sx={{ overflowWrap: 'break-word', paddingTop: '6px' }}
            fontSize={'15px'}
            fontWeight={500}
            lineHeight={'18px'}
          >
            {line}
          </Typography>
        )}
      </Box>
      <img
        src={copyIcon}
        alt={'copy-icon'}
        onClick={() => {
          navigator.clipboard.writeText(`${title}\n${link}\n${line || ''}`)
          setIsConversationIdCopied(true)
        }}
        style={{ cursor: 'pointer' }}
      ></img>
      <Snackbar
        open={isConversationIdCopied}
        autoHideDuration={1500}
        onClose={() => setIsConversationIdCopied(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {t('copyToConsoleMessage')}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default ChatGuideBox
