import { Box, Paper, Typography } from '@mui/material'

import { MessageSource, MessageType } from '../../models/conversation'

type Props = {
  message: MessageType
}

export const Message = ({ message }: Props): React.ReactElement => {
  const isPeer = message?.source === MessageSource.PEER
  const isSystem = message?.source === MessageSource.SYSTEM
  const isSystemInvisible = message?.source === MessageSource.SYSTEM_INVISIBLE

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isSystem ? 'center' : !isPeer ? 'flex-start' : 'flex-end',
        mb: 2,
      }}
    >
      {!isSystemInvisible && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: isPeer ? 'row' : 'row-reverse',
            alignItems: 'center',
          }}
        >
          {!isSystem ? (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                ml: !isPeer ? 1 : 0,
                mr: !isPeer ? 0 : 1,
                backgroundColor: !isPeer ? '#FFFFFF' : '#2276D0',
                color: !isPeer ? '' : '#FFFFFF',
                borderRadius: !isPeer ? '0px 20px 20px 20px' : '20px 0px 20px 20px',
                width: '370px',
              }}
            >
              <Typography
                variant="body1"
                justifyContent={'center'}
                fontFamily={'unset'}
                fontSize={'13px'}
                fontWeight={500}
                lineHeight={'18px'}
                sx={{
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-line',
                }}
              >
                {message?.message}
              </Typography>
            </Paper>
          ) : (
            <Typography
              variant="body1"
              textAlign={'center'}
              justifyContent={'center'}
              alignItems={'center'}
              fontSize={'13px'}
              fontWeight={isSystem ? 700 : 500}
              lineHeight={'18px'}
              maxWidth={'500px'}
              sx={{
                wordWrap: 'break-word',
              }}
            >
              {message?.message}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}
