export enum MessageSource {
  USER = 'USER',
  PEER = 'PEER',
  SYSTEM = 'SYSTEM',
  SYSTEM_INVISIBLE = 'SYSTEM_INVISIBLE',
}

export enum ConversationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Conversation {
  conversationId: string
  connectionId: string
  peer: string
  status: ConversationStatus
  twilioConversationId: string
  ttl: number
  messages: MessageType[]
  previewMessage: string
  createdAt: number
  updatedAt: number
  phoneNumber: string
}

export interface MessageType {
  message: string
  messageId: string
  createdAt: number
  source: MessageSource
}

export interface LastJsonMessage {
  action: string
  notificationId: string
  payload: string
}

export interface LastMessage {
  data: LastMessageData
  conversationId: string
}

export interface LastMessageData {
  message: string
  messageId: string
  createdAt: number
  source: string
}

export interface FormatedMessage {
  action: string
  body: MessageType
  conversationId: string
}

export interface LastMessageJSON {
  action: string
  data?: LastMessageData
  conversationId?: string
  notificationId?: string
  payload?: string
}
