/* eslint-disable prettier/prettier */
import * as React from 'react'
import { useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import { Alert, Box, Snackbar } from '@mui/material'
import { useTranslation } from 'react-i18next'

import DownArrowIcon from '../../assets/Icon_Chevron_Down.svg'
import BlockIcon from '../../assets/Icon_Ban.svg'
import CloseChat from '../../assets/Icon_End.svg'
import { useChat } from '../../context/chatContext'
import copyIcon from '../../assets/Icon_Copy.svg'

type Props = {
  handleOpenReportDialog: () => void
  handleOpenCloseChatDialog: () => void
}

const CloseChatMenu = ({
  handleOpenCloseChatDialog,
  handleOpenReportDialog,
}: Props): React.ReactElement => {
  const { t } = useTranslation('base')
  const { selectedConversationId } = useChat()
  const [isConversationIdCopied, setIsConversationIdCopied] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCopyBtnClick = () => {
    navigator.clipboard.writeText(getParsedUrlLink()).then(() => {
      setIsConversationIdCopied(true)
      handleClose()
    })
  }

  const getParsedUrlLink = () => {
    return `${process.env.REACT_APP_URL}?conversationId=${selectedConversationId}`
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Button
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          disableRipple
          sx={{
            color: 'black',
            fontWeight: 'bold',
            fontSize: 40,
            hover: 'none',
          }}
        >
          <Box
            sx={{
              background: '#FFFFFF',
              width: '40px',
              height: '40px',
              border: 'solid  #D9D9D9',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 'none',
            }}
          >
            <img src={DownArrowIcon} alt="DRK" height={12} width={12} />
          </Box>
        </Button>
      </Box>

      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            handleOpenReportDialog()
            handleClose()
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <img src={BlockIcon} width={16} height={16} />
            {t('reportUserDialogButtonText')}
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenCloseChatDialog()
            handleClose()
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <img src={CloseChat} width={16} height={16} />
            {t('closeChatButtonText')}
          </Box>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleCopyBtnClick()
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <img
              src={copyIcon}
              width={16}
              height={16}
              alt={'copy-icon'}
              style={{ cursor: 'pointer' }}
            />
            {t('copyConversationIdButtonText')}
          </Box>
        </MenuItem>
      </Menu>
      <Snackbar
        open={isConversationIdCopied}
        autoHideDuration={1500}
        onClose={() => setIsConversationIdCopied(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {t('copyToConsoleMessage')}
        </Alert>
      </Snackbar>
    </>
  )
}

export default CloseChatMenu
