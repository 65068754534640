import axios, { AxiosResponse } from 'axios'
import { conversationsURL, baseURL, acceptNotificatinURL } from '../api/enpoints'
import { ConversationStatus } from '../models/conversation'

let controller: AbortController | null

class chatService {
  getConversations = async (
    header: { Authorization: string },
    status?: string,
    peer?: string | null
  ): Promise<AxiosResponse> => {
    if (controller) {
      controller.abort()
    }

    controller = new AbortController()

    const url =
      status === ConversationStatus.ACTIVE
        ? `${conversationsURL}?status=${status}`
        : peer
        ? `${conversationsURL}?peer=${peer}`
        : `${conversationsURL}`

    return axios.get(url, { headers: header, signal: controller?.signal })
  }

  searchConversations = async (header: { Authorization: string }, search: string) => {
    return axios.get(conversationsURL + `?search=${search}`, { headers: header })
  }

  getConversationMessages = async (
    conversationId: string,
    header: { Authorization: string }
  ): Promise<AxiosResponse> => {
    return axios.get(baseURL + `/conversations/${conversationId}/messages`, {
      headers: header,
    })
  }

  postNotificationAnswer = async (
    header: { Authorization: string },
    answer: string,
    payload: string,
    notificationId: string
  ): Promise<AxiosResponse> => {
    const body = { answer, payload, notificationId }
    return axios.post(acceptNotificatinURL, body, { headers: header })
  }

  closeConversation = async (
    conversationId: string,
    header: { Authorization: string }
  ): Promise<AxiosResponse> => {
    const body = {}
    return axios.put(baseURL + `/conversations/${conversationId}/close`, body, {
      headers: header,
    })
  }

  reportUser = async (conversationId: string, header: { Authorization: string }) => {
    return axios.put(
      baseURL + `/conversations/${conversationId}/block`,
      {},
      {
        headers: header,
      }
    )
  }

  getStatistics = async (header: { Authorization: string }) => {
    return axios.get(baseURL + `/conversations/statistics`, {
      headers: header,
    })
  }
}

export default new chatService()
