import { Grid } from '@mui/material'
import { useEffect } from 'react'

import Chat from '../Chat'
import ChatList from '../ChatList'
import { useChat } from '../../../context/chatContext'

const ChatArchiveLayout = (): React.ReactElement => {
  const { setIsArchivedChats } = useChat()

  useEffect(() => {
    setIsArchivedChats(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      ml={7}
      sx={{
        maxWidth: 'calc(100% - 56px)',
        height: 'calc(100vh - 64px)',
        '*::-webkit-scrollbar': {
          width: '5px',
        },
        '*::-webkit-scrollbar-track': {
          background: 'white',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#554F4A',
          borderRadius: '2px',
        },
      }}
    >
      <Grid
        item
        xs={4}
        md={4}
        sx={{
          maxHeight: '100%',
          overflow: 'auto',
          borderRight: 'solid 1px #D9D9D9',
          maxWidth: 'calc(100% - 56px)',
          height: 'calc(100vh - 64px)',
          '*::-webkit-scrollbar': {
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            background: 'white',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#554F4A',
            borderRadius: '2px',
          },
        }}
      >
        <ChatList />
      </Grid>

      <Grid item xs={8} md={8}>
        <Chat />
      </Grid>
    </Grid>
  )
}

export default ChatArchiveLayout
