import { useEffect, useState } from 'react'

import chatService from '../services/chat'
import { MessageType } from '../models/conversation'

export const useSelectedConversations = (currentSession: () => Promise<any>) => {
  const [selectedConversationId, setSelectedConversationId] = useState('')
  const [selectedArchivedConversationId, setSelectedArchivedConversationId] = useState('')
  const [selectedConversationMessages, setSelectedConversationMessages] = useState<
    MessageType[] | null
  >(null)
  const [isLoadingMessages, setIsLoadingMessages] = useState(false)
  const [isArchivedChats, setIsArchivedChats] = useState(false)

  useEffect(() => {
    !isArchivedChats
      ? getSelectedConversationMessges(
          selectedConversationId,
          setSelectedConversationMessages,
          setIsLoadingMessages,
          currentSession
        )
      : getSelectedConversationMessges(
          selectedArchivedConversationId,
          setSelectedConversationMessages,
          setIsLoadingMessages,
          currentSession
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversationId, selectedArchivedConversationId, isArchivedChats])

  return {
    selectedConversationId,
    selectedArchivedConversationId,
    setSelectedConversationId,
    setSelectedArchivedConversationId,
    selectedConversationMessages,
    setSelectedConversationMessages,
    isLoadingMessages,
    setIsLoadingMessages,
    isArchivedChats,
    setIsArchivedChats,
  }
}

const getSelectedConversationMessges = async (
  selectedConversationsId: string,
  setSelectedConversationMessages: (body: MessageType[]) => void,
  setIsLoadingMessages: (body: boolean) => void,
  currentSession: () => Promise<any>
) => {
  const session = await currentSession()
  const authHeader = { Authorization: 'Bearer ' + session?.idToken.jwtToken }

  if (selectedConversationsId) {
    setIsLoadingMessages(true)
    chatService
      .getConversationMessages(selectedConversationsId, authHeader)
      .then((res) => {
        setSelectedConversationMessages(res?.data?.data?.messages)
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingMessages(false)
      })
  } else {
    setSelectedConversationMessages([])
  }
}

// move it to auth context
const cognitoPoolId = process.env.REACT_APP_COGNITO_POOL_CLIENT_ID
const lastAuthPeer = localStorage.getItem(
  `CognitoIdentityServiceProvider.${cognitoPoolId}.LastAuthUser`
)
const loginPeerToken = localStorage.getItem(
  `CognitoIdentityServiceProvider.${cognitoPoolId}.${lastAuthPeer}.idToken`
)
const authHeader = { Authorization: 'Bearer ' + loginPeerToken }
