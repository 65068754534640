import React from 'react'
import { ReactNode } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Layout from './layout/Layout'
import ChatLayout from './components/chat/ChatLayout'
import LoginLayout from './components/loginPage/LoginLayout'
import ProtectedRoute from './ProtectedRoute'
import ChatArchiveLayout from './components/chat/chatArchive/ChatArchiveLayout'
import AdminStatistic from './components/statisticPage/AdminStatistic'
import Help from './components/staticPages/helpPage/Help'
import FurtherAdvisory from './components/staticPages/furtherAdvisoryPage/FurtherAdvisory'
import Guide from './components/staticPages/chatGuidePage/Guide'
import AdminConversationInfo from './components/conversationInfoPage/AdminConversationInfo'
import SupportPage from './components/staticPages/SupportPage/supportPage'

const Router = (): React.ReactElement => {
  const getRoutingPages = (): ReactNode => {
    return (
      <Routes>
        <Route path="/login" element={<LoginLayout />} />
        <Route
          path="/"
          element={
            // avoid navigate not login user to chatLayout
            <Layout>
              <ProtectedRoute>
                <ChatLayout />
              </ProtectedRoute>
            </Layout>
          }
        />
        <Route
          path="/FAQ"
          element={
            <Layout>
              <ProtectedRoute>
                <Help />
              </ProtectedRoute>
            </Layout>
          }
        />
        <Route
          path="/chatGuide"
          element={
            <Layout>
              <ProtectedRoute>
                <Guide />
              </ProtectedRoute>
            </Layout>
          }
        />
        <Route
          path="/furtherAdvisory"
          element={
            <Layout>
              <ProtectedRoute>
                <FurtherAdvisory />
              </ProtectedRoute>
            </Layout>
          }
        />
        <Route
          path="/archivedChats"
          element={
            <Layout>
              <ProtectedRoute>
                <ChatArchiveLayout />
              </ProtectedRoute>
            </Layout>
          }
        />
        <Route
          path="/adminStatistics"
          element={
            <Layout>
              <ProtectedRoute>
                <AdminStatistic />
              </ProtectedRoute>
            </Layout>
          }
        />
        <Route
          path="/support"
          element={
            <Layout>
              <ProtectedRoute>
                <SupportPage />
              </ProtectedRoute>
            </Layout>
          }
        />
        <Route
          path="/adminConversationInfo"
          element={
            <Layout>
              <ProtectedRoute>
                <AdminConversationInfo />
              </ProtectedRoute>
            </Layout>
          }
        />
      </Routes>
    )
  }

  return <BrowserRouter>{getRoutingPages()}</BrowserRouter>
}

export default Router
