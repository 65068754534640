import { CssBaseline, AppBar, Box } from '@mui/material'
import { useContext } from 'react'

import { AuthContext } from '../context/loginContext'

import Sidebar from './components/Sidebar'
import TopBar from './components/TopBar'

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props): React.ReactElement => {
  const { getLastAuthPeer } = useContext(AuthContext)
  return (
    <Box
      sx={{
        '*::-webkit-scrollbar': {
          width: '5px',
        },
        '*::-webkit-scrollbar-track': {
          background: 'white',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#554F4A',
          borderRadius: '2px',
        },
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: 'white' }}
      >
        <TopBar />
      </AppBar>
      {getLastAuthPeer() ? <Sidebar /> : null}
      <Box sx={{ mt: 8, background: '#FBFBFB', minHeight: 'calc(100vh - 64px)' }}> {children}</Box>
    </Box>
  )
}

export default Layout
