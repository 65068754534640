import React, { createContext, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'

import { CognitoUser } from '../models/login'

Auth.configure({ storage: window.localStorage })

interface AuthContextProps {
  user: CognitoUser | null
  isLoading: boolean
  login: (name: string, password: string) => Promise<void>
  logout: () => Promise<void>
  getLastAuthPeer: () => string | null
  isAdmin: boolean
  currentSession: () => Promise<any>
  error: string
  conversationId: string
}

const defaultAuthContext: AuthContextProps = {
  user: null,
  isLoading: true,
  login: async () => {},
  logout: async () => {},
  getLastAuthPeer: () => null,
  isAdmin: true,
  currentSession: async () => {},
  error: '',
  conversationId: '',
}

export const AuthContext = createContext<AuthContextProps>(defaultAuthContext)

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<CognitoUser | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [conversationId, setConversationId] = useState('')

  const params = new URL(document.location.toString()).searchParams
  const conversationIdFromParam = params.get('conversationId')

  useEffect(() => {
    if (conversationIdFromParam) {
      setConversationId(conversationIdFromParam)
    }
  }, [conversationIdFromParam])

  const isAdmin =
    user?.signInUserSession?.accessToken?.payload['cognito:groups'] &&
    user?.signInUserSession?.accessToken?.payload['cognito:groups'][0] === 'admin'

  const getLastAuthPeer = (): string | null => {
    const cognitoPoolId = process.env.REACT_APP_COGNITO_POOL_CLIENT_ID
    const lastAuthPeer = localStorage.getItem(
      `CognitoIdentityServiceProvider.${cognitoPoolId}.LastAuthUser`
    )

    return lastAuthPeer
  }

  const currentSession = async () => {
    try {
      // eslint-disable-next-line
      const session = await Auth.currentSession()
      return session
    } catch (err) {
      setUser(null)
      // eslint-disable-next-line no-console
      console.log('current session failed')
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  const checkAuthenticatedUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      setUser(currentUser)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // setError(error)
      console.error('Error retrieving current user:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const login = async (name: string, password: string) => {
    try {
      setIsLoading(true)
      const user = await Auth.signIn(name, password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // User requires a new password
        // Prompt the user to enter a new password
        const newPassword = prompt('Please enter a new password')

        // Include email attribute as additionalAttributes
        const additionalAttributes = {
          email: 'fakemail@gmail.com',
        }

        await Auth.completeNewPassword(user, newPassword || 'Password', additionalAttributes)

        // Log in again with the updated password
        await Auth.signIn(name, newPassword || 'Password')
      }

      checkAuthenticatedUser()
      setIsLoading(false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsLoading(false)
      error.message && setError('Falscher Benutzername oder falsches Passwort.')
    }
  }

  const logout = async () => {
    try {
      await Auth.signOut()
      setUser(null)
      setConversationId('')
    } catch (error) {
      console.error('Logout error:', error)
      throw error
    }
  }

  useEffect(() => {
    checkAuthenticatedUser()
  }, [])

  // LOGS for dev

  // console.log('login CONTEXT user', user)

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        login,
        logout,
        getLastAuthPeer,
        isAdmin,
        currentSession,
        error,
        conversationId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
