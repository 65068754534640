import { Box, Container } from '@mui/material'

import StaticPageHeader from '../StaticPageHeader'
import { furtherAdvisoryContent } from '../../../i18n/locales/en/textFields'
import StaticPageBodyExtended from '../StaticPageBodyExtended'

const FurtherAdvisory = (): React.ReactElement => {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 64px)',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          background: '#fff',
        }}
      >
        <Container maxWidth={'md'}>
          <StaticPageHeader
            title={furtherAdvisoryContent.header.title}
            subtitle={furtherAdvisoryContent.header.subtitle}
          ></StaticPageHeader>
        </Container>
      </Box>
      <Box>
        <Container maxWidth={'md'}>
          <StaticPageBodyExtended staticPageContent={furtherAdvisoryContent} />
        </Container>
      </Box>
    </Box>
  )
}

export default FurtherAdvisory
