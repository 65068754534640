import React from 'react'

import { AuthProvider } from './context/loginContext'
import { NotificationProvider } from './context/notificationContext'
import Router from './router'

const App = () => (
  <AuthProvider>
    <NotificationProvider>
      <Router />
    </NotificationProvider>
  </AuthProvider>
)

export default App
