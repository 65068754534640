import { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Snackbar,
  Typography,
} from '@mui/material'
import ReactHtmlParser from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'
import { useTranslation } from 'react-i18next'

import arrowIcon from '../../assets/Icon_arrow_gray.svg'
import copyIcon from '../../assets/Icon_Copy.svg'

const AccordionIcon = () => {
  return <img src={arrowIcon} width={16} height={16} />
}

type StaticPageContent = {
  header: {
    title: string
    subtitle: string
  }
  body: {
    title?: string
    accordions: {
      title: string
      content: string[]
    }[]
  }
}

const StaticPageBodyExtended = ({
  staticPageContent,
}: {
  staticPageContent: StaticPageContent
}): React.ReactElement => {
  const { t } = useTranslation('base')
  const [isConversationIdCopied, setIsConversationIdCopied] = useState(false)

  return (
    <Box>
      {staticPageContent.body.title ? (
        <Typography
          sx={{ paddingBottom: '32px', paddingTop: '48px' }}
          fontSize={'24px'}
          lineHeight={'28px'}
          fontWeight={700}
          key={staticPageContent.body.title || 'key'}
        >
          {staticPageContent.body.title}
        </Typography>
      ) : (
        <Box key={staticPageContent.header.subtitle} sx={{ paddingTop: '64px' }} />
      )}
      {staticPageContent.body.accordions.map((accordion) => (
        <Accordion
          key={accordion.title}
          disableGutters={true}
          sx={{
            borderRadius: '8px !important',
            border: 'solid 1px #D9D9D9',
            '&:before': {
              backgroundColor: 'transparent !important',
            },
            marginBottom: '12px',
            boxShadow: 'none',
          }}
        >
          <AccordionSummary expandIcon={<AccordionIcon />}>
            <Typography fontSize={'18px'} fontWeight={500} lineHeight={'26px'} color={'#2276D0'}>
              {accordion.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {accordion.content.map((content, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'start' }}>
                <img
                  src={copyIcon}
                  width={16}
                  height={16}
                  style={{ cursor: 'pointer', marginTop: '5px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      sanitizeHtml(content, { allowedTags: [], allowedAttributes: false })
                    )
                    setIsConversationIdCopied(true)
                  }}
                />
                <Box
                  fontSize={'18px'}
                  fontWeight={400}
                  lineHeight={'26px'}
                  sx={{ paddingLeft: '8px !important', marginTop: '-18px' }}
                >
                  {ReactHtmlParser(content.replace('<br />', ''))}
                </Box>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      <Snackbar
        open={isConversationIdCopied}
        autoHideDuration={1500}
        onClose={() => setIsConversationIdCopied(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
          {t('copyToConsoleMessage')}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default StaticPageBodyExtended
