import { useTranslation } from 'react-i18next'
import { Dialog, Box, Button, Typography } from '@mui/material'

import { colors } from '../../theme'
import { useChat } from '../../context/chatContext'

type Props = {
  openReportDialog: boolean
  handleCloseReportDialog: () => void
}

const ReportChatDialog = ({
  openReportDialog,
  handleCloseReportDialog,
}: Props): React.ReactElement => {
  const { t } = useTranslation('base')
  const { handleReportUser } = useChat()

  const handleSendFeedback = () => {
    handleReportUser()
    handleCloseReportDialog()
  }

  const onCloseDialog = (event: HTMLInputElement, reason: string) => {
    if (reason && reason == 'backdropClick') return
    handleCloseReportDialog()
  }

  return (
    <Dialog
      open={openReportDialog}
      onClose={onCloseDialog}
      PaperProps={{ sx: { borderRadius: '12px' } }}
    >
      <Box
        borderRadius="25%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '480px',
          p: '36px',
        }}
      >
        <Typography variant="h5" fontWeight="bold" textAlign={'center'}>
          {t('reportDialogChatTitle')}
        </Typography>

        <Typography variant="body1" textAlign={'center'} fontWeight={500} pt={2} pr={4} pl={4}>
          {t('reportDialogChatTitleHelper')}
        </Typography>

        <Button
          variant="contained"
          onClick={handleSendFeedback}
          sx={{
            background: colors.drkRot,
            width: '214px',
            height: '56px',
            borderRadius: '24px',
            mt: '32px',
            textTransform: 'none',
            boxShadow: 'none',
          }}
        >
          <Typography
            variant="body1"
            fontSize="18px"
            lineHeight="18px"
            fontWeight={700}
            color="primary.light"
          >
            {t('reportDialogChatConfirmButtonText')}
          </Typography>
        </Button>

        <Button
          variant="text"
          onClick={() => handleCloseReportDialog()}
          sx={{ width: '214px', borderRadius: '24px', mt: '32px', textTransform: 'none' }}
        >
          <Typography variant="body1" fontSize="18px" fontWeight={500} color="primary.dark">
            {t('reportDialogChatBackButtonChatText')}
          </Typography>
        </Button>
      </Box>
    </Dialog>
  )
}

export default ReportChatDialog
