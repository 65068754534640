import { Box, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import routes from '../../constants/routes'
import { chatGuideAcordeonContent, chatGuideBoxesContent } from '../../i18n/locales/en/textFields'
import { ChatGuideAcordeonContentType } from '../../models/guides'

import ChatGuideAccordion from './ChatGuideAccordion'
import ChatGuideBox from './ChatGuideBox'

const ChatGuide = () => {
  const { t } = useTranslation('base')

  return (
    <Box
      sx={{
        p: 4,
        width: '100%',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          fontSize={'16px'}
          fontWeight={'500'}
          lineHeight={'22px'}
          sx={{ paddingBottom: '24px' }}
        >
          {t('chatGuideTittle')}
        </Typography>
        {/* ACCORDEONS */}
        <Box pb={4} sx={{ borderBottom: '1px solid #D9D9D9' }}>
          {chatGuideAcordeonContent.map((accordeon: ChatGuideAcordeonContentType) => (
            <ChatGuideAccordion key={accordeon.title} content={accordeon} />
          ))}
          <Typography
            component={NavLink}
            to={routes.chatGuide}
            fontSize={'15px'}
            fontWeight={500}
            lineHeight={'22px'}
            color={'#2276D0'}
            sx={{ textDecoration: 'none' }}
          >
            {t('linkToFurtherAdvisoryTitle')}
          </Typography>
        </Box>

        {/* CHAT GUIDE BOXES */}
        <Typography
          fontSize={'16px'}
          pt={4}
          fontWeight={'500'}
          lineHeight={'22px'}
          sx={{ paddingBottom: '24px' }}
        >
          {t('chatGuideBoxesTitle')}
        </Typography>
        <Box>
          {chatGuideBoxesContent.map((box) => (
            <ChatGuideBox
              key={box.title}
              title={box.title}
              link={box.link}
              line={box?.line}
            ></ChatGuideBox>
          ))}
        </Box>
        <Typography
          component={NavLink}
          to={routes.furtherAdvisory}
          fontSize={'15px'}
          fontWeight={500}
          lineHeight={'22px'}
          color={'#2276D0'}
          ml={1}
          sx={{ textDecoration: 'none' }}
        >
          {t('linkToHelp')}
        </Typography>
      </Box>
    </Box>
  )
}

export default ChatGuide
