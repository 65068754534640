import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Button, Link, Grid } from '@mui/material'

import Logo from '../../assets/Logo_Final.svg'
import { AuthContext } from '../../context/loginContext'
import image from '../../assets/Background-Img_1200.jpg'
import Spinner from '../common/Spinner'
import { loginFooterTexts } from '../../i18n/locales/en/textFields'

const LoginLayout = (): React.ReactElement => {
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const { user, login, isLoading, error } = useContext(AuthContext)

  const handleLogin = async () => {
    try {
      login(name, password)
      // !error && navigate('/')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('any error', error.message)
    }
  }

  useEffect(() => {
    if (user) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <Grid xl={12} display={'flex'} height={'100vh'}>
      {!isLoading ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '50%',
              mr: 4,
              ml: 4,
            }}
          >
            <img src={Logo} alt="DRK" height={160} width={160} />
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, fontSize: '32px', lineHeight: '40px' }}
              textAlign={'center'}
            >
              Anmeldung
            </Typography>
            <form onSubmit={handleLogin}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '48px',
                  minWidth: '400px',
                }}
              >
                <label htmlFor="name">
                  <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px' }}>
                    Benutzername
                  </Typography>
                </label>
                <input
                  type="text"
                  id="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{
                    border: name.length > 0 ? 'solid 1px #2276D0' : 'solid 1px #B4B4B4',
                    outline: name.length > 0 ? 'solid 1px #2276D0' : 'none',
                    borderRadius: '8px',
                    padding: '20px',
                    maxHeight: '56px',
                    color: '#2276D0',
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '24px' }}>
                <label htmlFor="password">
                  <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '18px' }}>
                    Passwort
                  </Typography>
                </label>
                <input
                  type="password"
                  id="password"
                  minLength={8}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    border: password.length > 7 ? 'solid 1px #2276D0' : 'solid 1px #B4B4B4',
                    outline: password.length > 7 ? 'solid 1px #2276D0' : 'none',
                    borderRadius: '8px',
                    padding: '20px',
                    maxHeight: '56px',
                    color: password.length > 7 ? '#2276D0' : 'black',
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '32px' }}>
                {error && (
                  <Typography
                    variant="body1"
                    paddingBottom={'18px'}
                    sx={{ fontWeight: 700, fontSize: '15px', color: '#E60005' }}
                  >
                    {error}
                  </Typography>
                )}
                <Button
                  type="submit"
                  style={{
                    textTransform: 'none',
                    backgroundColor: '#E60005',
                    fontSize: '18px',
                    color: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    padding: '18px 0 18px 0',
                    height: '56px',
                    cursor: 'pointer',
                  }}
                >
                  Jetzt anmelden
                </Button>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: '32px',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '15px' }}>
                    {loginFooterTexts[0]}
                  </Typography>
                  <Link href="https://realtalk.help/datenschutz" target="_blank">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 400, fontSize: '15px', color: '#000' }}
                    >
                      {loginFooterTexts[1]}
                    </Typography>
                  </Link>
                  <Link href="https://realtalk.help/impressum" target="_blank">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 400, fontSize: '15px', color: '#000' }}
                    >
                      {loginFooterTexts[2]}
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </form>
          </Box>

          <Box width={'50%'} maxHeight={'100%'}>
            <img
              src={image}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      )}
    </Grid>
  )
}

export default LoginLayout
