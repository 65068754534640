const help = '/FAQ'
const chatGuide = '/chatGuide'
const login = '/login'
const archivedChats = '/archivedChats'
const adminStatistics = '/adminStatistics'
const furtherAdvisory = '/furtherAdvisory'
const adminConversationInfo = '/adminConversationInfo'
const support = '/support'

const routes = {
  chat: '/',
  help,
  chatGuide,
  login,
  archivedChats,
  adminStatistics,
  furtherAdvisory,
  adminConversationInfo,
  support,
}

export default routes
